<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" novalidate #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit(form)" *ngIf="!loading">
    <app-form-title [titles]="['UDP_RTP', 'SOURCE']" [isEdit]="isEdit" [isClone]="isClone"></app-form-title>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
                    <!-- Name -->
                    <app-input-generic-field
                        zmid="form_name"
                        duplicateName="sourceNames"
                        [formControl]="nameControl"
                        [textFieldName]="'NAME' | translate"
                        [disabled]="isEdit || saving"
                        [isParentFormSubmitted]="form.submitted"
                        type="text"
                    ></app-input-generic-field>
                    <!-- Access Tags -->

                    <zx-access-tags-select
                        zmid="form_access-tags"
                        [formControl]="tagsControl"
                        [label]="'TAGS' | translate"
                        name="resource_tag_ids"
                        [isParentFormSubmitted]="form.submitted"
                    ></zx-access-tags-select>
                    <!-- Alerting Profile -->
                    <div class="form-group">
                        <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                            "ALERTING_PROFILE" | translate
                        }}</label>
                        <zx-alerting-profile-select
                            name="alertingProfile"
                            [(model)]="source.alertingProfile"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                        ></zx-alerting-profile-select>
                    </div>
                    <!-- Location -->
                    <div class="form-group">
                        <label for="location_text"
                            >{{ "LOCATION" | translate
                            }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                            <ng-template #LocationContent>{{ "TOOLTIP.SOURCE_LOCATION" | translate }}</ng-template></label
                        >
                        <zx-location-search [(model)]="source.location.address"></zx-location-search>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'UDP_RTP' | translate }} {{ 'CONFIGURATION' | translate }}">
                            {{ "UDP_RTP" | translate }} {{ "CONFIGURATION" | translate }}
                        </h3>
                    </div>

                    <!-- Use RTP -->
                    <div class="form-group">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="use_rtp" name="use_rtp" [(ngModel)]="useRtp" />
                            <label class="form-check-label" for="use_rtp"
                                >{{ "USE_RTP_PROTOCOL" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="UseRTP" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #UseRTP
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.USE_RTP_PROTOCOL" | translate }}
                                    </p></ng-template
                                >
                                <app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                        </div>
                    </div>
                    <!-- Use FEC -->
                    <div *ngIf="useRtp" class="form-group">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="use_fec" name="use_fec" [(ngModel)]="source.use_fec" />
                            <label class="form-check-label" for="use_fec"
                                >{{ "USE_FEC" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="UseFEC" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #UseFEC
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.USE_FEC" | translate }}
                                    </p></ng-template
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                        </div>
                    </div>
                    <!-- Keep RTP Headers -->
                    <div *ngIf="useRtp" class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="keep_rtp_headers"
                                name="keep_rtp_headers"
                                [(ngModel)]="source.keep_rtp_headers"
                            />
                            <label class="form-check-label" for="keep_rtp_headers"
                                >{{ "KEEP_RTP_HEADERS" | translate }}
                                <app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                        </div>
                    </div>

                    <!-- Multicast address -->
                    <div class="form-group">
                        <label for="multicast_address" [ngClass]="{ 'is-invalid': form.submitted && multicast_address.errors }"
                            >{{ "MULTICAST_ADDRESS" | translate
                            }}<fa-icon icon="info-circle" size="sm" [ngbTooltip]="MulticastAddressContent" triggers="hover click" [closeDelay]="500"></fa-icon
                            ><ng-template #MulticastAddressContent
                                ><p class="mb-0">
                                    {{ "TOOLTIP.MULTICAST_ADDRESS_SOURCE" | translate }}
                                </p></ng-template
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <input
                            type="text"
                            id="multicast_address"
                            name="multicast_address"
                            placeholder="{{ 'MULTICAST_ADDRESS_PLACEHOLDER' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && multicast_address.errors }"
                            [(ngModel)]="source.multicast_address"
                            pattern="^2(?:2[4-9]|3\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$"
                            #multicast_address="ngModel"
                        />
                        <div *ngIf="multicast_address.invalid" class="invalid-feedback">
                            <div *ngIf="multicast_address.errors.pattern">{{ "MULTICAST_ADDRESS_MUST_BE_IN_RANGE" | translate }}.</div>
                        </div>
                    </div>
                    <!-- SSM -->
                    <div *ngIf="source.multicast_address && source.multicast_address !== ''" class="form-group">
                        <label for="multicast_source" [ngClass]="{ 'is-invalid': form.submitted && multicast_source.errors }"
                            >{{ "MULTICAST_SOURCE" | translate }}
                            <fa-icon icon="info-circle" size="sm" [ngbTooltip]="MulticastAddressContent" triggers="hover click" [closeDelay]="500"></fa-icon
                            ><ng-template #MulticastAddressContent
                                ><p class="mb-0">
                                    {{ "TOOLTIP.MULTICAST_SOURCE" | translate }}
                                </p> </ng-template
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <input
                            type="text"
                            id="multicast_source"
                            name="multicast_source"
                            placeholder="{{ 'MULTICAST_SOURCE_PLACEHOLDER' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && multicast_source.errors }"
                            [(ngModel)]="source.multicast_source"
                            pattern="^(?:20\d|21\d|22[0-3]|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$"
                            #multicast_source="ngModel"
                        />
                        <div *ngIf="multicast_source.invalid" class="invalid-feedback">
                            <div *ngIf="multicast_source.errors.pattern">
                                {{ "MULTICAST_SOURCE" | translate }} {{ "MULTICAST_SOURCE_MUST_BE_IN_RANGE" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Port -->
                    <div class="form-group">
                        <label for="listening_port" [ngClass]="{ 'is-invalid': form.submitted && listening_port.errors }">
                            {{ "PORT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <input
                            type="number"
                            id="listening_port"
                            name="listening_port"
                            min="1"
                            max="65535"
                            placeholder="{{ 'PORT' | translate }}"
                            [(ngModel)]="source.listening_port"
                            required
                            pattern="^\d+$"
                            #listening_port="ngModel"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && listening_port.errors }"
                        />
                        <div *ngIf="listening_port.invalid" class="invalid-feedback">
                            <div *ngIf="listening_port.errors.required">{{ "PORT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="listening_port.errors.pattern">
                                {{ "PORT" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                            </div>
                            <div *ngIf="listening_port.errors.min || listening_port.errors.max">
                                {{ "PORT" | translate }} {{ "MUST_BE_BETWEEN_1_AND_65535" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Max Bitrate -->
                    <div class="form-group">
                        <label for="max_bitrate" [ngClass]="{ 'is-invalid': form.submitted && maxBitrate.errors }">
                            {{ "MAX_BITRATE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </label>
                        <ng-select
                            id="max_bitrate"
                            name="max_bitrate"
                            required
                            [items]="maxBitrates"
                            [clearable]="false"
                            placeholder="{{ 'SELECT' | translate }} {{ 'MAX_BITRATE' | translate }}"
                            [(ngModel)]="source.max_bitrate"
                            bindLabel="name"
                            bindValue="value"
                            #maxBitrate="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && maxBitrate.errors }"
                        >
                        </ng-select>
                        <div *ngIf="maxBitrate.invalid" class="invalid-feedback">
                            <div *ngIf="maxBitrate.errors.required">{{ "MAX_BITRATE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'SOURCE' | translate }} {{ 'CONFIGURATION' | translate }}">
                            {{ "SOURCE" | translate }} {{ "CONFIGURATION" | translate }}
                        </h3>
                    </div>

                    <!-- Ingest Cluster -->
                    <div class="form-group">
                        <label for="broadcaster_cluster_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                            >{{ "INGEST_CLUSTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-cluster-select
                            name="broadcaster_cluster_id"
                            [(model)]="source.broadcaster_cluster_id"
                            [ngModel]="source.broadcaster_cluster_id"
                            required="true"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                            (modelChange)="clusterSelectionChange(source.broadcaster_cluster_id, true)"
                        ></zx-cluster-select>
                        <div *ngIf="form.controls.broadcaster_cluster_id?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.broadcaster_cluster_id?.errors.required">
                                {{ "INGEST_CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Target Broadcaster/s -->
                    <div class="form-group">
                        <label for="target_broadcaster_id" [ngClass]="{ 'is-invalid': form.submitted && targetBroadcasters.errors }"
                            >{{ "TARGET_BROADCASTER/S" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <ng-select
                            id="target_broadcaster_id"
                            name="target_broadcaster_id"
                            [items]="targetBXs"
                            [clearable]="false"
                            bindValue="id"
                            bindLabel="name"
                            groupBy="type"
                            placeholder="{{ 'SELECT_BROADCASTER/S' | translate }}"
                            [(ngModel)]="source.target_broadcaster_id"
                            (change)="getBroadcasterDetails(source.target_broadcaster_id)"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && targetBroadcasters.errors }"
                            [disabled]="!source.broadcaster_cluster_id"
                            #targetBroadcasters="ngModel"
                            [loading]="targetBXsLoading"
                            required
                        >
                            <ng-template ng-optgroup-tmp let-item="item">
                                <span>{{ "SELECT_SPECIFIC_BROADCASTER" | translate }}</span>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                        </ng-select>
                        <div *ngIf="targetBroadcasters.invalid" class="invalid-feedback">
                            <div *ngIf="targetBroadcasters.errors.required">{{ "TARGET_BROADCASTER/S" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Input NIC Select -->
                    <div class="form-group">
                        <label for="input_nic">{{ "INPUT_NIC" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon></label>
                        <ng-select
                            id="input_nic"
                            name="input_nic"
                            [items]="inputNICs"
                            [clearable]="false"
                            bindValue="value"
                            bindLabel="name"
                            [loading]="targetNICLoading"
                            placeholder="{{ 'SELECT_INPUT_NIC' | translate }}"
                            [(ngModel)]="source.input_nic"
                            [disabled]="source.broadcaster_cluster_id == null"
                        >
                        </ng-select>
                    </div>
                    <!-- PID Mapping Profile -->
                    <div class="form-group">
                        <label for="pidMappingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                            >{{ "PID_MAPPING_PROFILE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-pid-mapping-profile-select
                            name="pidMappingProfile"
                            [(model)]="source.pid_mapping_profile_id"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                        ></zx-pid-mapping-profile-select>
                        <div *ngIf="form.controls.pidMappingProfile?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.pidMappingProfile?.errors.required">
                                {{ "PID_MAPPING_PROFILE" | translate }} {{ "IS_REQUIRED" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- PID Changes -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="monitor_pids_change"
                                name="monitor_pids_change"
                                [(ngModel)]="source.monitor_pids_change"
                            />
                            <label class="form-check-label" for="monitor_pids_change">{{ "ALERT_ON_PID_CHANGES" | translate }}</label>
                        </div>
                    </div>
                    <!-- Traceroute History -->
                    <div class="form-group" *ngIf="accountTracerouteHistory">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="traceroute_history"
                                name="traceroute_history"
                                [(ngModel)]="source.traceroute_history"
                            />
                            <label class="form-check-label" for="traceroute_history">{{ "ENABLE_TRACEROUTE_HISTORY" | translate }} </label>
                        </div>
                    </div>
                    <!-- SCTE-35 Log -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="log_scte"
                                name="log_scte"
                                (change)="!source.process_scte_reports ? (source.report_scte_warnings = false) : false"
                                [(ngModel)]="source.process_scte_reports"
                            />
                            <label class="form-check-label" for="log_scte">{{ "LOG_SCTE_35" | translate }}</label>
                        </div>
                        <small>{{ "SCTE_VERSION_AND_CLUSTER_CONFIGURATION_REQUIRED" | translate }}</small>
                    </div>
                    <!-- SCTE-35 Alert -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="alert_scte"
                                name="alert_scte"
                                [disabled]="!source.process_scte_reports"
                                [(ngModel)]="source.report_scte_warnings"
                            />
                            <label class="form-check-label" for="alert_scte">{{ "ENABLE_SCTE_35_ALERTS" | translate }}</label>
                        </div>
                        <small>{{ "REQUIRES_SCTE_35_LOG" | translate }}</small>
                    </div>
                    <!-- Common Fields -->
                    <app-source-form-common-fields [form]="form" [isEdit]="isEdit" [source]="source"></app-source-form-common-fields>

                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="source.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>
                    <!-- Advanced -->
                    <app-advanced-section-form
                        [showBilling]="true"
                        [showAutopullBilling]="true"
                        [showAutoMTU]="true"
                        [showInputDeviceBind]="true"
                        [model]="source"
                        [form]="form"
                        [isEdit]="isEdit"
                        [showFrozenVideoMinimalDuration]="true"
                        [showBlancVideoMinimalDuration]="true"
                    ></app-advanced-section-form>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";

import { Source } from "../../../models/shared";
import { SOURCE_CONTENT_ANALYSIS, SourcesService } from "../sources.service";

@Component({
    selector: "app-source-form-common-fields",
    templateUrl: "./source-form-common.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    providers: []
})
export class SourceFormCommonFieldsComponent implements OnInit {
    @Input() source: Source;
    @Input() isEdit: boolean;
    @Input() form;
    @Input() allowScte35Insert = true;
    showMoreWebRTCOptions = false;
    CONTENT_ANALYSIS = SOURCE_CONTENT_ANALYSIS;
    contentAnalysisInput: SOURCE_CONTENT_ANALYSIS;

    constructor(private sourcesService: SourcesService) {}

    ngOnInit(): void {
        this.contentAnalysisInput = this.sourcesService.getSourceContentAnalysisState(this.source);
    }

    contentAnalysisInputChanges() {
        switch (this.contentAnalysisInput) {
            case SOURCE_CONTENT_ANALYSIS.FULL:
                this.source.content_analysis = 1;
                this.source.tr101_analysis = 1;
                break;
            case SOURCE_CONTENT_ANALYSIS.TR101_ONLY:
                this.source.tr101_analysis = 1;
                this.source.content_analysis = 0;
                this.source.monitor_cei608708_cc = 0;
                break;
            case SOURCE_CONTENT_ANALYSIS.NONE:
                this.source.content_analysis = 0;
                this.source.tr101_analysis = 0;
                this.source.monitor_cei608708_cc = 0;
                break;
        }
    }
}

import { Component, Input } from "@angular/core";
import { MediaConnectSource } from "../../../../models/shared";

@Component({
    selector: "app-mc-source-content-analysis",
    templateUrl: "./mc-source-content-analysis.component.html"
})
export class MediaConnectSourceContentAnalysisComponent {
    @Input() source: MediaConnectSource;
}

<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" novalidate #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit(form)" *ngIf="!loading">
    <app-form-title [titles]="['AWS_ELEMENTAL_LINK', 'SOURCE']" [isEdit]="isEdit" [isClone]="isClone"></app-form-title>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
                    <!-- Name -->
                    <app-input-generic-field
                        zmid="form_name"
                        duplicateName="sourceNames"
                        [formControl]="nameControl"
                        [textFieldName]="'NAME' | translate"
                        [disabled]="isEdit || saving"
                        [isParentFormSubmitted]="form.submitted"
                        type="text"
                    ></app-input-generic-field>

                    <!-- Access Tags -->
                    <zx-access-tags-select
                        zmid="form_access-tags"
                        [formControl]="tagsControl"
                        [label]="'TAGS' | translate"
                        name="resource_tag_ids"
                        [isParentFormSubmitted]="form.submitted"
                    ></zx-access-tags-select>

                    <!-- Alerting Profile -->
                    <div class="form-group">
                        <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                            "ALERTING_PROFILE" | translate
                        }}</label>
                        <zx-alerting-profile-select
                            name="alertingProfile"
                            [(model)]="source.alertingProfile"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                        ></zx-alerting-profile-select>
                    </div>

                    <!-- Currently extended CDI and JPEGXS sources are configured on flow -->
                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'SOURCE' | translate }} {{ 'CONFIGURATION' | translate }}">
                            {{ "SOURCE" | translate }} {{ "CONFIGURATION" | translate }}
                        </h3>
                    </div>

                    <div class="form-group">
                        <small>{{ "AWS_ELEMENTAL_LINK_MUST_MATCH_CHANNEL" | translate }}</small>
                    </div>

                    <!-- AWS Account -->
                    <div class="form-group">
                        <label for="aws_account_id" [ngClass]="{ 'is-invalid': form.submitted && awsAccount.errors }">
                            {{ "AWS_ACCOUNT" | translate }}<fa-icon icon="asterisk" size="xs" *ngIf="!isEdit"></fa-icon>
                        </label>
                        <ng-select
                            id="aws_account_id"
                            name="aws_account_id"
                            required
                            [items]="awsAccounts"
                            [clearable]="false"
                            bindValue="id"
                            bindLabel="name"
                            placeholder="{{ 'SELECT' | translate }} {{ 'AWS_ACCOUNT' | translate }}"
                            [(ngModel)]="source.elemental_link_account_id"
                            [disabled]="isEdit"
                            (change)="updateAWSAccountRegions()"
                            #awsAccount="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && awsAccount.errors }"
                        >
                        </ng-select>
                        <div *ngIf="awsAccount.invalid" class="invalid-feedback">
                            <div *ngIf="awsAccount.errors.required">{{ "AWS_ACCOUNT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <!-- Region -->
                    <div class="form-group">
                        <label for="region" [ngClass]="{ 'is-invalid': form.submitted && region.errors }">
                            {{ "REGION" | translate }}<fa-icon icon="asterisk" size="xs" *ngIf="!isEdit"></fa-icon>
                        </label>
                        <ng-select
                            id="region"
                            name="region"
                            required
                            [items]="awsRegions"
                            [clearable]="false"
                            placeholder="{{ 'SELECT' | translate }} {{ 'REGION' | translate }}"
                            [(ngModel)]="source.elemental_link_region"
                            (change)="updateAWSRegion()"
                            bindLabel="name"
                            bindValue="id"
                            #region="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && region.errors }"
                            [disabled]="isEdit || !source.elemental_link_account_id || awsRegions.length === 0"
                            [loading]="awsAccountRegionsLoading"
                        >
                        </ng-select>
                        <div *ngIf="region.invalid" class="invalid-feedback">
                            <div *ngIf="region.errors.required">{{ "REGION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <!-- Elemental Link -->
                    <div class="form-group">
                        <label for="elemental_link_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.elemental_link_id?.errors }"
                            >{{ "ELEMENTAL_LINK" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <ng-select
                            id="elemental_link_id"
                            name="elemental_link_id"
                            [items]="awsElementalLinks"
                            [clearable]="false"
                            bindValue="Id"
                            bindLabel="Id"
                            placeholder="{{ 'SELECT' | translate }} {{ 'ELEMENTAL_LINK' | translate }}"
                            [(ngModel)]="source.elemental_link_id"
                            #elementalLink="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && elementalLink.errors }"
                            required
                            [disabled]="
                                isEdit ||
                                !source.elemental_link_account_id ||
                                !source.elemental_link_region ||
                                awsRegions.length === 0 ||
                                awsElementalLinks.length === 0 ||
                                awsElementalLinksLoading
                            "
                            [loading]="awsElementalLinksLoading"
                        ></ng-select>
                        <div *ngIf="elementalLink.invalid" class="invalid-feedback">
                            <div *ngIf="elementalLink.errors.required">{{ "ELEMENTAL_LINK" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="source.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />

                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

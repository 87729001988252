<div class="table-responsive">
    <table class="table table-sm mb-0" [ngClass]="{ bordered: bordered }">
        <thead>
            <tr>
                <th scope="col" class="m-160px">{{ "LINK" | translate }}</th>
                <th scope="col" class="m-160px">{{ "BITRATE" | translate }} [kbps]</th>
                <th scope="col" class="m-160px">{{ "UP_TIME" | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let link of source.status?.links">
                <td>{{ link.nic }} => {{ link.remote_ip }}</td>
                <td>{{ link.bitrate | bitrate }}</td>
                <td>{{ link.up_time | uptime }}</td>
            </tr>
        </tbody>
    </table>
</div>

import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { SourcesService } from "../sources.service";
import { Source } from "../../../models/shared";

@Injectable({
    providedIn: "root"
})
export class SourceResolverService implements Resolve<Source> {
    constructor(private ss: SourcesService) {}

    resolve(): Observable<Source> | Observable<never> {
        return new Observable((observe: Subscriber<Source>) => {
            this.ss.loadSourcesSuperFast().subscribe(() => {
                observe.next();
                observe.complete();
            });
        });
    }
}

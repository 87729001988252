import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Source } from "../../../../models/shared";
import { DeviceDetectorService } from "ngx-device-detector";
import { Constants } from "src/app/constants/constants";
import { SourcesService } from "../../sources.service";

@Component({
    selector: "app-source-stream",
    templateUrl: "./source-stream.component.html"
})
export class SourceStreamComponent {
    @Input() source: Source;
    canVLC = false;
    showOverlay = true;
    expandPrograms = true;

    urls = Constants.urls;
    constants = Constants;

    constructor(private deviceService: DeviceDetectorService, private ss: SourcesService) {
        this.canVLC = ["windows", "mac"].includes(this.deviceService.os.toLocaleLowerCase());

        if (localStorage.getItem("streamInfoExpandPrograms")) {
            if (localStorage.getItem("streamInfoExpandPrograms") === "false") this.expandPrograms = false;
            else this.expandPrograms = true;
        }
    }

    resetTR101() {
        this.ss.resetTR101(this.source);
    }

    saveExpandPrograms() {
        localStorage.setItem("streamInfoExpandPrograms", this.expandPrograms.toString());
    }
}

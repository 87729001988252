<form>
    <div zmid="title" class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1>{{ "CREATE_NEW_SOURCE" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <!-- Type -->
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <h3>{{ "ZEN Master" | translate }}</h3>
                    <div class="card mb-3">
                        <ul zmid="source-types" class="list-group list-group-flush type-list">
                            <li zmid="zixi-type" class="list-group-item" (click)="goto('zixi')" [class.disabled]="sourceResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "ZIXI" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "ZIXI_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }} </span>
                                </p>
                            </li>
                            <li
                                zmid="monitor-only-type"
                                class="list-group-item"
                                (click)="goto('monitor_only')"
                                [class.disabled]="sourceResourceTags.length === 0"
                            >
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "MONITOR_ONLY" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "MONITOR_ONLY_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li zmid="failover-type" class="list-group-item" (click)="goto('hitless')" [class.disabled]="sourceResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "HITLESS_FAILOVER" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "HITLESS_FAILOVER_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li zmid="transcoded-type" class="list-group-item" (click)="goto('transcoded')" [class.disabled]="sourceResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "TRANSCODED" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "TRANSCODED_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li zmid="pid-mapping-type" class="list-group-item" (click)="goto('pid-mapping')" [class.disabled]="pidResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "PID_MAPPING" | translate }}</span> -
                                    <span *ngIf="pidResourceTags.length > 0">{{ "PID_MAPPING_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="pidResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li
                                zmid="inter-cluster-type"
                                class="list-group-item"
                                (click)="goto('inter-cluster')"
                                [class.disabled]="sourceResourceTags.length === 0"
                            >
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "INTERCLUSTER" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "INTERCLUSTER_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li zmid="udp_rtp-type" class="list-group-item" (click)="goto('udp-rtp')" [class.disabled]="sourceResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "UDP_RTP" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "UDP_RTP_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li zmid="file-type" class="list-group-item" (click)="goto('file')" [class.disabled]="sourceResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "FILE" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "FILE_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li zmid="srt-type" class="list-group-item" (click)="goto('srt')" [class.disabled]="sourceResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "SRT" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "SRT_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li zmid="rist-type" class="list-group-item" (click)="goto('rist')" [class.disabled]="sourceResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "RIST" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "RIST_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li zmid="ndi-type" class="list-group-item" (click)="goto('ndi')" [class.disabled]="sourceResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "NDI" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "NDI_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li zmid="rtmp-type" class="list-group-item" (click)="goto('rtmp')" [class.disabled]="sourceResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "RTMP_SOURCE" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "RTMP_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li zmid="hls-type" class="list-group-item" (click)="goto('hls_pull')" [class.disabled]="sourceResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "HLS_PULL_SOURCE" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "HLS_PULL_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li zmid="demux-type" class="list-group-item" (click)="goto('demux')" [class.disabled]="sourceResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "DEMUX_SOURCE" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "DEMUX_SOURCE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li zmid="multiview" class="list-group-item" (click)="goto('multiview')" [class.disabled]="sourceResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "MULTIVIEW_SOURCE" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "MULTIVIEW_SOURCE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li zmid="multiplex-type" class="list-group-item" (click)="goto('multiplex')" [class.disabled]="sourceResourceTags.length === 0">
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "MULTIPLEX_SOURCE" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "MULTIPLEX_SOURCE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                    <h3>{{ "AWS_MEDIA" | translate }}</h3>
                    <div class="card mb-3">
                        <ul zmid="source-types" class="list-group list-group-flush type-list">
                            <li
                                zmid="mediaconnect-type"
                                class="list-group-item"
                                (click)="goto('mediaconnect')"
                                [class.disabled]="mediaconnectResourceTags.length === 0"
                            >
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "AWS_MEDIACONNECT" | translate }}</span> -
                                    <span *ngIf="mediaconnectResourceTags.length > 0">{{ "MEDIACONNECT_SOURCE_TYPE_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="mediaconnectResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                            <li
                                zmid="elemental-link"
                                class="list-group-item"
                                (click)="goto('elemental_link')"
                                [class.disabled]="sourceResourceTags.length === 0"
                            >
                                <p class="card-text">
                                    <span class="text-primary bold">{{ "AWS_ELEMENTAL_LINK" | translate }}</span> -
                                    <span *ngIf="sourceResourceTags.length > 0">{{ "AWS_ELEMENTAL_LINK_DESCRIPTION" | translate }}</span>
                                    <span *ngIf="sourceResourceTags.length === 0">{{ "NO_PERMISSIONS" | translate }}</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                    <hr class="mt-0" />
                    <div class="text-start">
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SourcesService } from "../sources.service";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { SharedService } from "../../../services/shared.service";

import { Source, BroadcasterInput } from "../../../models/shared";
import { ErrorService } from "../../../components/error/error.service";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleCasePipe } from "@angular/common";
import { TitleService } from "../../../services/title.service";
import { TranslateService } from "@ngx-translate/core";
import { UsersService } from "../../account-management/users/users.service";
import { ControlContainer, UntypedFormControl, NgForm, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";

@Component({
    selector: "app-source-form-monitor",
    templateUrl: "./source-form.component.html",
    providers: [TitleCasePipe],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceFormMonitorComponent implements OnInit, OnDestroy {
    source: Source;
    sources: Source[];
    sourceName: string;
    sourceId: number;
    sourceNames: string[];
    newSources: Partial<Source>[];

    existingSource: Source;

    showAdvanced = false;
    action: string;
    loading = true;
    saving = false;
    constants = Constants;

    submitted = false;
    minLength = 2;
    isEdit = false;
    singleMode = false;
    startDisabled = false;
    savingMultiple: boolean;
    finishedSaving: boolean;

    broadcasterDetailsLoading = false;
    broadcasterInputs: BroadcasterInput[] = [];
    selectedBroadcasterInputs: BroadcasterInput[] = [];

    maxBitrates = Constants.maxBitrates;
    broadcasterInputsFilter: string;

    accountTracerouteHistory: boolean;

    page = 1;
    pageSize = 10;

    MAX_INPUTS = 25;

    private sourcesSubscription: Subscription;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ss: SourcesService,
        private broadcastersService: BroadcastersService,
        private sharedService: SharedService,
        private modalService: ModalService,
        private errorService: ErrorService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe,
        private userService: UsersService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.sourceName = params.get("name");
            this.sourceId = urlBuilder.decode(params.get("sourceId"));
            this.action = params.get("action");
            if (this.sourceName && this.sourceId) {
                this.source = Object.assign({}, this.ss.getCachedSource(this.sourceName, null, this.sourceId));
                this.existingSource = _.cloneDeep(this.source);

                // Check if source found in cache, if not get sources and source
                if (this.sharedService.isEmptyObject(this.source) || !this.source.hasFullDetails) {
                    this.ss
                        .refreshSources(true)
                        .pipe(take(1))
                        .subscribe(async () => {
                            this.source = this.ss.getCachedSource(this.sourceName, null, this.sourceId);
                            this.existingSource = _.cloneDeep(this.source);

                            await this.ss.refreshSource(this.source).toPromise();

                            this.source = Object.assign(
                                {},
                                this.ss.getCachedSource(this.sourceName, null, this.sourceId)
                            );
                            this.existingSource = _.cloneDeep(this.source);

                            this.prepForm();
                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        });
    }

    prepForm() {
        if (this.action) {
            this.tagsControl.setValue(this.source.resourceTags);
            this.singleMode = true;

            this.isEdit = true;

            if (this.source) {
                this.nameControl.setValue(this.source.name);
                if (this.source.broadcaster_id) {
                    this.getBroadcasterDetails(this.source.broadcaster_id);
                }
            }
        }

        if (!this.source && !this.isEdit) {
            this.source = new Source();
            this.source.type = "monitor_only";
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle(
            this.translate.instant("SOURCE") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.source && this.source.name ? this.source.name : "")
        );
    }

    resetForm() {
        // Source
        this.source.mediaconnect_mode = "pull";
        this.source.latency = 4000;
        this.source.content_analysis = 1;
        this.source.tr101_analysis = 1;
        this.source.monitor_pids_change = 0;
        this.source.traceroute_history = 0;
        this.source.output_nic = "";
        this.tagsControl.setValue([]);
        this.source.broadcaster_cluster_id = null;
        this.source.broadcaster_id = null;
        this.source.feeder_id = null;
        this.source.transcode_source_id = null;
        this.nameControl.setValue(null);
        this.source.allow_outputs = 0;
        this.source.outputs_password = null;
        this.source.transcode_profile_id = null;
        this.source.transcode_cbr_kbps = null;
        this.source.webrtc_mode = "";
        this.source.location = {};
        this.source.autopull_latency = null;
        this.source.billing_code = null;
        this.source.billing_password = null;
        this.source.autopull_billing_code = null;
        this.source.autopull_billing_password = null;
        this.source.autopull_mtu = null;
        this.source.freeze_detection_timeout_sec = 10;
        this.source.blank_detection_timeout_sec = 10;

        // this.source.webrtc_thumbnail = 0;
        // UI
        this.broadcasterInputs = [];
        this.selectedBroadcasterInputs = [];
    }

    getClusterSourceNames(id: number) {
        const filteredSources = _.filter(this.sources, source => id === source.broadcaster_cluster_id);
        let filteredSourceNames = _.map(filteredSources, "name");

        if (this.isEdit) filteredSourceNames = _.without(filteredSourceNames, this.source.name);

        this.sourceNames = filteredSourceNames;
    }

    async getBroadcasterDetails(id: number, clearConfig?: boolean) {
        this.broadcasterInputs = [];
        if (clearConfig) {
            this.source.output_nic = "";
            this.selectedBroadcasterInputs = [];
        }
        this.broadcasterDetailsLoading = true;
        if (!id) {
            this.broadcasterDetailsLoading = false;
            return;
        }
        const broadcaster = await this.broadcastersService.refreshBroadcaster(id, true).toPromise();

        if (broadcaster) {
            this.source.broadcaster_cluster_id = broadcaster.broadcaster_cluster_id;
            this.source.target_broadcaster_id = id;

            if (broadcaster.status && broadcaster.status.inputs) {
                this.broadcasterInputs = this.sharedService.sort(broadcaster.status.inputs || [], "id", "asc");
            } else {
                this.broadcasterInputs = [];
            }
        } else {
            this.broadcasterInputs = [];
        }

        // reset selected input if it no longer exists on the bx
        if (!this.broadcasterInputs.find(input => input.id === this.source.input_id)) this.source.input_id = null;

        this.broadcasterDetailsLoading = false;
    }

    selectBroadcasterInput(input: BroadcasterInput) {
        if (this.selectedBroadcasterInputs.length >= this.MAX_INPUTS) return;

        this.selectedBroadcasterInputs.push(input);
        this.broadcasterInputs = this.broadcasterInputs.filter(i => {
            return i.id !== input.id;
        });
    }

    deselectBroadcasterInput(input: BroadcasterInput) {
        this.broadcasterInputs.push(input);
        this.broadcasterInputs = this.sharedService.sort(this.broadcasterInputs || [], "id", "asc");
        this.selectedBroadcasterInputs = this.selectedBroadcasterInputs.filter(i => {
            return i.id !== input.id;
        });
    }

    getBroadcasterInputs(inputs: BroadcasterInput[], filter = "") {
        return (inputs || []).filter(i => {
            return (i.id || "").toLowerCase().includes(filter.toLowerCase());
        });
    }

    ngOnInit() {
        // Sources
        this.ss.refreshSources(true);
        this.sourcesSubscription = this.ss.sources.subscribe((sources: Source[]) => {
            this.sources = sources;
        });

        this.userService.isTracerouteHistory.pipe(take(1)).subscribe(bool => {
            this.accountTracerouteHistory = bool;
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.sourcesSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;
        let newSources = [];

        if (!this.singleMode && this.source.type === "monitor_only") {
            newSources = _.map(this.selectedBroadcasterInputs, input => {
                return {
                    name: input.id,
                    broadcaster_cluster_id: this.source.broadcaster_cluster_id,
                    target_broadcaster_id: this.source.target_broadcaster_id,
                    feeder_id: null,
                    broadcaster_id: null,
                    input_id: null,
                    max_bitrate: input.max_bitrate,
                    latency: this.source.latency,
                    monitor_pids_change: this.source.monitor_pids_change ? 1 : 0,
                    traceroute_history: this.source.traceroute_history ? 1 : 0,
                    content_analysis: this.source.content_analysis ? 1 : 0,
                    tr101_analysis: this.source.tr101_analysis ? 1 : 0,
                    monitor_cei608708_cc: this.source.monitor_cei608708_cc ? 1 : 0,
                    resource_tag_ids: _.map(this.tagsControl.value, "id"),
                    alerting_profile_id: this.source.alertingProfile.id,
                    password: this.source.password || "",
                    encryption: this.source.encrypt ? "auto" : "none",
                    encryption_key: "",
                    allow_outputs: this.source.allow_outputs ? 1 : 0,
                    outputs_password: this.source.outputs_password || "",
                    monitor_only: 1,
                    report_scte_warnings: this.source.report_scte_warnings,
                    process_scte_reports: this.source.process_scte_reports,
                    location: this.source.location.address,
                    muted: this.source.muted,
                    is_enabled:
                        !this.isEdit && this.startDisabled
                            ? 0
                            : !this.isEdit && !this.startDisabled
                            ? 1
                            : this.source.is_enabled
                };
            });
        } else {
            newSources.push({
                name: this.isEdit ? undefined : this.nameControl.value,
                broadcaster_cluster_id: this.source.broadcaster_cluster_id,
                autobond: 0,
                feeder_id: null,
                broadcaster_id: null,
                target_broadcaster_id: this.source.target_broadcaster_id,
                input_id: null,
                max_bitrate: this.source.max_bitrate,
                latency: this.source.latency,
                monitor_pids_change: this.source.monitor_pids_change ? 1 : 0,
                traceroute_history: this.source.traceroute_history ? 1 : 0,
                content_analysis: this.source.content_analysis ? 1 : 0,
                tr101_analysis: this.source.tr101_analysis ? 1 : 0,
                monitor_cei608708_cc: this.source.monitor_cei608708_cc ? 1 : 0,
                resource_tag_ids: _.map(this.tagsControl.value, "id"),
                alerting_profile_id: this.source.alertingProfile.id,
                password: this.source.password || "",
                encryption: "none",
                encryption_key: "",
                allow_outputs: this.source.allow_outputs ? 1 : 0,
                outputs_password: this.source.outputs_password || "",
                monitor_only: 1,
                output_nic: this.source.output_nic,
                bondedLinks: [],
                report_scte_warnings: this.source.report_scte_warnings,
                process_scte_reports: this.source.process_scte_reports,
                location: this.source.location.address,
                muted: this.source.muted,
                is_enabled:
                    !this.isEdit && this.startDisabled
                        ? 0
                        : !this.isEdit && !this.startDisabled
                        ? 1
                        : this.source.is_enabled
            });
        }

        newSources.forEach(s => {
            s.disable_autopull = this.source.disable_autopull;
            s.autopull_latency = this.source.autopull_latency;
            s.webrtc_mode = this.source.webrtc_mode;
            s.autopull_mtu = this.source.autopull_mtu;
            s.billing_code = this.source.billing_code;
            s.billing_password = this.source.billing_password;
            s.autopull_billing_code = this.source.autopull_billing_code;
            s.autopull_billing_password = this.source.autopull_billing_password;
            s.freeze_detection_timeout_sec = this.source.freeze_detection_timeout_sec;
            s.blank_detection_timeout_sec = this.source.blank_detection_timeout_sec;
            s.hide_thumbnail = this.source.hide_thumbnail;
            s.enable_scte35_insertion = this.source.enable_scte35_insertion;
        });

        if (this.isEdit) {
            // filter only relevant bonding fields to prevent unneccesary updates
            this.existingSource.bondedLinks = this.existingSource.bondedLinks?.map(l =>
                _.extend({
                    nic_ip: l.nic_ip,
                    device: l.device,
                    max_bitrate: l.max_bitrate,
                    backup: l.backup
                })
            );
            const changedData = this.sharedService.getZixiObjDiff(newSources[0], this.existingSource, []);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedSource = await this.ss.updateSource(this.source, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedSource;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "SOURCE",
                        async () => {
                            const updateAndRestartSource = await this.ss.updateSource(this.source, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartSource) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart monitor only source", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                            } else this.saving = false;
                        },
                        this.source.name
                    );
                    this.saving = false;
                } else if (updatedSource) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update monitor only source", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
            }
        } else {
            if (newSources.length === 1) {
                const result = await this.ss.addSource(newSources[0]);
                if (result) {
                    this.mixpanelService.sendEvent("create monitor only source");
                    this.router.navigate(urlBuilder.getRegularSourceUrl(result.id, result.name));
                } else this.saving = false;
            } else {
                this.savingMultiple = true;
                this.finishedSaving = false;
                this.newSources = newSources;

                await Promise.all(
                    this.newSources.map(async newSource => {
                        const result = await this.ss.addSource(newSource);
                        if (result) {
                            this.mixpanelService.sendEvent("create monitor only source");
                            Object.assign(newSource, { _frontData: { finished: true, error: false } });
                        } else {
                            let errorMessage: string;
                            this.errorService.currentHttpErrorResponse.pipe(take(1)).subscribe(errorResponse => {
                                errorMessage = errorResponse.error.error;
                            });
                            Object.assign(newSource, {
                                _frontData: { error: true, errorMsg: errorMessage, finished: true }
                            });
                        }
                    })
                );

                this.finishedSaving = true;
                this.saving = false;
            }
        }
    }

    cancel() {
        if (this.isEdit)
            this.router.navigate(urlBuilder.getRegularSourceUrl(this.existingSource.id, this.existingSource.name));
        else this.router.navigate([Constants.urls.sources]);
    }

    back() {
        this.router.navigate([Constants.urls.sources, "new"]);
    }

    done() {
        this.router.navigate([Constants.urls.sources]);
    }
}

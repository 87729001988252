<content-loading-animation *ngIf="loading"></content-loading-animation>
<form
    id="form"
    novalidate
    #form="ngForm"
    (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && selectedSources.length !== 0 && onSubmit(form)"
    *ngIf="!loading"
    class="page-container.expanded"
>
    <app-form-title [titles]="['MULTIPLEX_SOURCE', 'SOURCE']" [isEdit]="isEdit" [isClone]="isClone"></app-form-title>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
                    <!-- Name -->
                    <app-input-generic-field
                        zmid="form_name"
                        duplicateName="sourceNames"
                        [formControl]="nameControl"
                        [textFieldName]="'NAME' | translate"
                        [disabled]="isEdit || saving"
                        [isParentFormSubmitted]="form.submitted"
                        type="text"
                    ></app-input-generic-field>

                    <!-- Access Tags -->
                    <zx-access-tags-select
                        zmid="form_access-tags"
                        [formControl]="tagsControl"
                        [label]="'TAGS' | translate"
                        name="resource_tag_ids"
                        [isParentFormSubmitted]="form.submitted"
                    ></zx-access-tags-select>

                    <!-- Alerting Profile -->
                    <div class="form-group">
                        <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                            "ALERTING_PROFILE" | translate
                        }}</label>
                        <zx-alerting-profile-select
                            name="alertingProfile"
                            [(model)]="source.alertingProfile"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                        ></zx-alerting-profile-select>
                    </div>
                    <!-- Location -->
                    <div class="form-group">
                        <label for="location_text"
                            >{{ "LOCATION" | translate
                            }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                            <ng-template #LocationContent>{{ "TOOLTIP.SOURCE_LOCATION" | translate }}</ng-template></label
                        >
                        <zx-location-search [(model)]="source.location.address"></zx-location-search>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                            {{ "CONFIGURATION" | translate }}
                        </h3>
                    </div>

                    <!-- Ingest Cluster -->
                    <div class="form-group">
                        <label for="broadcaster_cluster_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                            >{{ "INGEST_CLUSTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-cluster-select
                            name="broadcaster_cluster_id"
                            [(model)]="source.broadcaster_cluster_id"
                            [ngModel]="source.broadcaster_cluster_id"
                            required="true"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                            (modelChange)="clusterSelectionChange(source.broadcaster_cluster_id)"
                        ></zx-cluster-select>
                        <div *ngIf="form.controls.broadcaster_cluster_id?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.broadcaster_cluster_id?.errors.required">
                                {{ "INGEST_CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Target Broadcaster/s -->
                    <div class="form-group">
                        <label for="target_broadcaster_id" [ngClass]="{ 'is-invalid': form.submitted && targetBroadcasters.errors }"
                            >{{ "TARGET_BROADCASTER/S" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <ng-select
                            id="target_broadcaster_id"
                            name="target_broadcaster_id"
                            [items]="targetBXs"
                            [clearable]="false"
                            bindValue="id"
                            bindLabel="name"
                            groupBy="type"
                            placeholder="{{ 'SELECT_BROADCASTER/S' | translate }}"
                            [(ngModel)]="source.target_broadcaster_id"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && targetBroadcasters.errors }"
                            [disabled]="!source.broadcaster_cluster_id"
                            #targetBroadcasters="ngModel"
                            [loading]="targetBXsLoading"
                            required
                        >
                            <ng-template ng-optgroup-tmp let-item="item">
                                <span>{{ "SELECT_SPECIFIC_BROADCASTER" | translate }}</span>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                        </ng-select>
                        <div *ngIf="targetBroadcasters.invalid" class="invalid-feedback">
                            <div *ngIf="targetBroadcasters.errors.required">{{ "TARGET_BROADCASTER/S" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'SOURCES' | translate }}">
                            {{ "SOURCES" | translate }}
                        </h3>
                    </div>

                    <app-multiplex-source-selection
                        [formSubmitted]="form.submitted"
                        [selectedSources]="selectedSources"
                        [(targetBitrate)]="source.target_bitrate"
                        (targetBitrateEvent)="source.target_bitrate = $event"
                    ></app-multiplex-source-selection>

                    <!-- Drop Inactive Multiplex -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="drop_inactive_multiplex"
                                name="drop_inactive_multiplex"
                                [(ngModel)]="source.drop_inactive_multiplex"
                            />
                            <label class="form-check-label" for="drop_inactive_multiplex">{{ "DROP_INACTIVE_MULTIPLEX" | translate }}</label>
                        </div>
                    </div>

                    <!-- PID Changes -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="monitor_pids_change"
                                name="monitor_pids_change"
                                [(ngModel)]="source.monitor_pids_change"
                            />
                            <label class="form-check-label" for="monitor_pids_change">{{ "ALERT_ON_PID_CHANGES" | translate }}</label>
                        </div>
                    </div>
                    <!-- SCTE-35 Log -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="log_scte"
                                name="log_scte"
                                (change)="!source.process_scte_reports ? (source.report_scte_warnings = false) : false"
                                [(ngModel)]="source.process_scte_reports"
                            />
                            <label class="form-check-label" for="log_scte">{{ "LOG_SCTE_35" | translate }}</label>
                        </div>
                        <small>{{ "SCTE_VERSION_AND_CLUSTER_CONFIGURATION_REQUIRED" | translate }}</small>
                    </div>
                    <!-- SCTE-35 Alert -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="alert_scte"
                                name="alert_scte"
                                [disabled]="!source.process_scte_reports"
                                [(ngModel)]="source.report_scte_warnings"
                            />
                            <label class="form-check-label" for="alert_scte">{{ "ENABLE_SCTE_35_ALERTS" | translate }}</label>
                        </div>
                        <small>{{ "REQUIRES_SCTE_35_LOG" | translate }}</small>
                    </div>

                    <app-source-form-common-fields [form]="form" [isEdit]="isEdit" [source]="source"></app-source-form-common-fields>

                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="isMuted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>
                    <!-- Advanced -->
                    <app-advanced-section-form
                        [showBilling]="true"
                        [showAutopullBilling]="true"
                        [showAutoMTU]="true"
                        [showInputDeviceBind]="true"
                        [model]="source"
                        [form]="form"
                        [isEdit]="isEdit"
                        [showFrozenVideoMinimalDuration]="true"
                        [showBlancVideoMinimalDuration]="true"
                    ></app-advanced-section-form>

                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

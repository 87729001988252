<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" novalidate #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit(form)" *ngIf="!loading">
    <app-form-title [titles]="['NDI', 'SOURCE']" [isEdit]="isEdit" [isClone]="isClone"></app-form-title>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
                    <!-- Name -->
                    <app-input-generic-field
                        zmid="form_name"
                        duplicateName="sourceNames"
                        [formControl]="nameControl"
                        [textFieldName]="'NAME' | translate"
                        [disabled]="isEdit || saving"
                        [isParentFormSubmitted]="form.submitted"
                        type="text"
                    ></app-input-generic-field>

                    <!-- Access Tags -->
                    <zx-access-tags-select
                        zmid="form_access-tags"
                        [formControl]="tagsControl"
                        [label]="'TAGS' | translate"
                        name="resource_tag_ids"
                        [isParentFormSubmitted]="form.submitted"
                    ></zx-access-tags-select>

                    <!-- Alerting Profile -->
                    <div class="form-group">
                        <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                            "ALERTING_PROFILE" | translate
                        }}</label>
                        <zx-alerting-profile-select
                            name="alertingProfile"
                            [(model)]="source.alertingProfile"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                        ></zx-alerting-profile-select>
                    </div>
                    <!-- Location -->
                    <div class="form-group">
                        <label for="location_text"
                            >{{ "LOCATION" | translate
                            }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                            <ng-template #LocationContent>{{ "TOOLTIP.SOURCE_LOCATION" | translate }}</ng-template></label
                        >
                        <zx-location-search [(model)]="source.location.address"></zx-location-search>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                            {{ "CONFIGURATION" | translate }}
                        </h3>
                    </div>

                    <!-- Ingest Cluster -->
                    <div class="form-group">
                        <label for="broadcaster_cluster_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                            >{{ "INGEST_CLUSTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-cluster-select
                            name="broadcaster_cluster_id"
                            [(model)]="source.broadcaster_cluster_id"
                            [ngModel]="source.broadcaster_cluster_id"
                            required="true"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                            (modelChange)="clusterSelectionChange(source.broadcaster_cluster_id, true)"
                        ></zx-cluster-select>
                        <div *ngIf="form.controls.broadcaster_cluster_id?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.broadcaster_cluster_id?.errors.required">
                                {{ "INGEST_CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Target Broadcaster/s -->
                    <div class="form-group">
                        <label for="target_broadcaster_id" [ngClass]="{ 'is-invalid': form.submitted && targetBroadcasters.errors }"
                            >{{ "TARGET_BROADCASTER/S" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <ng-select
                            id="target_broadcaster_id"
                            name="target_broadcaster_id"
                            [items]="targetBXs"
                            [clearable]="false"
                            bindValue="id"
                            bindLabel="name"
                            groupBy="type"
                            placeholder="{{ 'SELECT_BROADCASTER/S' | translate }}"
                            [(ngModel)]="source.target_broadcaster_id"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && targetBroadcasters.errors }"
                            [disabled]="!source.broadcaster_cluster_id"
                            #targetBroadcasters="ngModel"
                            [loading]="targetBXsLoading"
                            required
                            (change)="targetSelectionChange(source.target_broadcaster_id)"
                        >
                            <ng-template ng-optgroup-tmp let-item="item">
                                <span>{{ "SELECT_SPECIFIC_BROADCASTER" | translate }}</span>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                        </ng-select>
                        <div *ngIf="targetBroadcasters.invalid" class="invalid-feedback">
                            <div *ngIf="targetBroadcasters.errors.required">{{ "TARGET_BROADCASTER/S" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <!-- NDI Stream -->
                    <div class="form-group">
                        <div>
                            <label for="ndiStream" [ngClass]="{ 'is-invalid': form.submitted && form.controls.ndiStream?.invalid }"
                                >{{ "NDI" | translate }} {{ "STREAM" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ></label>
                        </div>
                        <ng-select
                            id="ndiStream"
                            name="ndiStream"
                            [items]="ndiStreams"
                            [clearable]="false"
                            placeholder="{{ 'SELECT_NDI_STREAM' | translate }}"
                            [(ngModel)]="source.input_id"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && form.controls.ndiStream?.invalid }"
                            [disabled]="source.broadcaster_cluster_id == null || source.target_broadcaster_id == null"
                            [loading]="targetBXsLoading"
                            required
                            #ndiStream="ngModel"
                        ></ng-select>
                        <div *ngIf="form.controls.ndiStream?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.ndiStream?.errors.required">
                                {{ "NDI" | translate }} {{ "STREAM" | translate }} {{ "IS_REQUIRED" | translate }}.
                            </div>
                        </div>
                    </div>

                    <!-- Transcoding Profiles -->
                    <div class="form-group">
                        <label for="transcodingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                            >{{ "PROFILE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-transcoding-profile-select
                            name="transcodingProfile"
                            [(model)]="transcodeProfile"
                            required="true"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                        ></zx-transcoding-profile-select>
                        <div *ngIf="form.controls.transcodingProfile?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.transcodingProfile?.errors.required">{{ "PROFILE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- GPU -->
                    <div class="form-group">
                        <div class="input-group align-items-center">
                            <div class="input-group-prepend">
                                <div class="input-group">
                                    <div class="form-check form-check-inline">
                                        <input type="checkbox" class="form-check-input" id="multiGpu" name="multiGpu" [(ngModel)]="multiGpu" />
                                        <label class="form-check-label" for="multiGpu">{{ "ASSIGN_TO_GPU" | translate }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="input-group-div">
                                <ng-select
                                    name="gpu_id"
                                    class="form-control"
                                    [searchable]="false"
                                    [clearable]="false"
                                    [(ngModel)]="source.gpu_id"
                                    [items]="[0, 1, 2, 3]"
                                    [disabled]="!multiGpu"
                                ></ng-select>
                            </div>
                        </div>
                    </div>
                    <!-- Pad to CBR -->
                    <div class="form-group">
                        <label for="transcode_cbr_kbps">{{ "PAD_TO_CBR" | translate }} [kbps]</label>
                        <input
                            type="number"
                            id="transcode_cbr_kbps"
                            name="transcode_cbr_kbps"
                            placeholder="{{ 'SET_CBR_BITRATE' | translate }}"
                            class="form-control"
                            [(ngModel)]="source.transcode_cbr_kbps"
                        />
                        <small>{{ "LEAVE_BLANK_FOR_VBR" | translate }}</small>
                    </div>
                    <!-- Audio Reference Level -->
                    <div class="form-group">
                        <label for="audioRefLevel" [ngClass]="{ 'is-invalid': form.submitted && form.controls.audioRefLevel?.invalid }">{{
                            "NDI_AUDIO_REF_LEVEL" | translate
                        }}</label>
                        <input
                            type="number"
                            id="audioRefLevel"
                            name="audioRefLevel"
                            placeholder="{{ 'NDI_AUDIO_REF_LEVEL' | translate }}"
                            class="form-control"
                            min="0"
                            max="20"
                            [(ngModel)]="source.audio_ref_level"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.audioRefLevel?.invalid }"
                            required
                        />
                        <div *ngIf="form.controls.audioRefLevel?.invalid" class="invalid-feedback">
                            <div
                                *ngIf="
                                    form.controls.audioRefLevel?.errors.min ||
                                    form.controls.audioRefLevel?.errors.max ||
                                    form.controls.audioRefLevel?.errors.required
                                "
                            >
                                {{ "NDI_AUDIO_REF_LEVEL" | translate }} {{ "MUST_BE_BETWEEN_0_AND_20" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- PID Changes -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="monitor_pids_change"
                                name="monitor_pids_change"
                                [(ngModel)]="source.monitor_pids_change"
                            />
                            <label class="form-check-label" for="monitor_pids_change">{{ "ALERT_ON_PID_CHANGES" | translate }}</label>
                        </div>
                    </div>
                    <!-- Common Fields -->
                    <app-source-form-common-fields [form]="form" [isEdit]="isEdit" [source]="source"></app-source-form-common-fields>
                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="source.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>

                    <!-- Audio source config -->
                    <app-collapsed-form-section
                        title="{{ 'AUDIO_SOURCES_CONFIGURATIONS' | translate }}"
                        [defaultShowSection]="true"
                        (updateVisibility)="updateAudioSourcesConfigurationGroup()"
                    >
                        <app-zx-ndi-audio-sources [audioSourcesConfigurationControl]="audioSourcesConfigurationGroup"></app-zx-ndi-audio-sources>
                    </app-collapsed-form-section>

                    <!-- Advanced -->
                    <app-advanced-section-form
                        [showAutopullBilling]="true"
                        [showAutoMTU]="true"
                        [model]="source"
                        [form]="form"
                        [isEdit]="isEdit"
                        [showFrozenVideoMinimalDuration]="true"
                        [showBlancVideoMinimalDuration]="true"
                    ></app-advanced-section-form>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

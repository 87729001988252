import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SourcesService } from "../sources.service";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { SharedService } from "../../../services/shared.service";
import { TranslateService } from "@ngx-translate/core";

import { Source, InputNIC } from "../../../models/shared";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleCasePipe } from "@angular/common";
import { TitleService } from "../../../services/title.service";
import { ControlContainer, UntypedFormControl, NgForm, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";

@Component({
    selector: "app-source-form-pid-mapping",
    templateUrl: "./source-form.component.html",
    providers: [TitleCasePipe],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceFormPidMappingComponent implements OnInit, OnDestroy {
    source: Source;
    sources: Source[];
    sourceId: number;
    sourceName: string;
    sourceNames: string[];
    inputNICs: InputNIC[] = [];

    existingSource: Source;

    showAdvanced = false;
    action: string;
    loading = true;
    saving = false;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    startDisabled = false;
    targetNICLoading = false;
    constants = Constants;

    targetBXsLoading = false;
    targetBXs = [
        { name: this.translate.instant("PREFER_PRIMARY_BROADCASTERS"), id: -1, cluster: null },
        { name: this.translate.instant("PRIMARY_BROADCASTERS_ONLY"), id: -2 },
        { name: this.translate.instant("BACKUP_BROADCASTERS_ONLY"), id: -3 },
        { name: this.translate.instant("PREFER_BACKUP_BROADCSTER"), id: -4, cluster: null }
    ];
    targetBXsBase = this.targetBXs;

    private sourcesSubscription: Subscription;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private ss: SourcesService,
        private broadcastersService: BroadcastersService,
        private sharedService: SharedService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.sourceName = params.get("name");
            this.sourceId = urlBuilder.decode(params.get("sourceId"));
            this.action = params.get("action");
            if (this.sourceName && this.sourceId) {
                this.source = Object.assign({}, this.ss.getCachedSource(this.sourceName, null, this.sourceId));
                this.existingSource = _.cloneDeep(this.source);

                // Check if source found in cache, if not get sources and source
                if (this.sharedService.isEmptyObject(this.source) || !this.source.hasFullDetails) {
                    this.ss
                        .refreshSources(true)
                        .pipe(take(1))
                        .subscribe(async () => {
                            this.source = this.ss.getCachedSource(this.sourceName, null, this.sourceId);
                            this.existingSource = _.cloneDeep(this.source);

                            await this.ss.refreshSource(this.source).toPromise();

                            this.source = Object.assign(
                                {},
                                this.ss.getCachedSource(this.sourceName, null, this.sourceId)
                            );
                            this.existingSource = _.cloneDeep(this.source);

                            this.prepForm();
                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        });
    }

    prepForm() {
        if (this.action) {
            this.tagsControl.setValue(this.source.resourceTags);
            if (this.action === "edit") {
                this.isEdit = true;
                this.nameControl.setValue(this.source.name);
            } else if (this.action === "clone") {
                this.isClone = true;
                this.source.name = "";
                this.source.muted = this.source.active_mute ? 1 : 0;
            }

            if (this.source) {
                if (this.source.broadcaster_cluster_id) {
                    this.clusterSelectionChange(this.source.broadcaster_cluster_id, false);
                }

                if (this.source.target_broadcaster_id > 0) {
                    this.getBroadcasterDetails(this.source.target_broadcaster_id);
                }
            }
        }

        if (!this.source && !this.isClone && !this.isEdit) {
            this.source = new Source();
            this.source.type = "pid-map";
            this.source.protocol = "pid-map";
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle(
            this.translate.instant("SOURCE") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.source && this.source.name ? this.source.name : "")
        );
    }

    resetForm() {
        // Source
        this.source.content_analysis = 1;
        this.source.tr101_analysis = 1;
        this.source.monitor_pids_change = 0;
        this.source.output_nic = "";
        this.source.input_nic = "";
        this.tagsControl.setValue([]);
        this.source.broadcaster_cluster_id = null;
        this.nameControl.setValue(null);
        this.source.allow_outputs = 0;
        this.source.outputs_password = null;
        this.source.pid_mapping_profile_id = null;
        this.source.webrtc_mode = "";
        this.source.location = {};
        this.source.autopull_latency = null;
        // this.source.webrtc_thumbnail = 0;
        this.source.billing_code = null;
        this.source.billing_password = null;
        this.source.autopull_billing_code = null;
        this.source.autopull_billing_password = null;
        this.source.autopull_mtu = null;
        this.source.freeze_detection_timeout_sec = 10;
        this.source.blank_detection_timeout_sec = 10;
    }

    async clusterSelectionChange(id: number, clusterChanged: boolean) {
        //  Clear specific broadcser selection since cluster is about to change.
        if (clusterChanged && this.source && this.source.target_broadcaster_id >= 0)
            this.source.target_broadcaster_id = undefined;

        this.getClusterSourceNames(id);
        this.getTargetBroadcasters(id);
    }

    getClusterSourceNames(id: number) {
        const filteredSources = _.filter(this.sources, source => id === source.broadcaster_cluster_id);
        let filteredSourceNames = _.map(filteredSources, "name");

        if (this.isEdit) filteredSourceNames = _.without(filteredSourceNames, this.source.name);

        this.sourceNames = filteredSourceNames;
    }

    async getTargetBroadcasters(id: number) {
        this.targetBXsLoading = true;
        this.targetBXs = this.targetBXsBase;
        if (!id) {
            this.targetBXsLoading = false;
            return;
        }
        const broadcasters = await this.broadcastersService.refreshBroadcasters(id, true).toPromise();
        if (broadcasters && broadcasters.length > 0) {
            this.targetBXs = this.targetBXsBase.concat(
                _.map(broadcasters, broadcaster => {
                    return {
                        id: broadcaster.id,
                        name: broadcaster.name,
                        type: "broadcaster",
                        generalStatus: broadcaster.generalStatus
                    };
                })
            );
        }
        this.targetBXsLoading = false;
    }

    async getBroadcasterDetails(id: number) {
        this.targetNICLoading = true;
        this.inputNICs = [];

        const broadcaster = await this.broadcastersService.refreshBroadcaster(id, true).toPromise();
        if (broadcaster && broadcaster.status) {
            if (broadcaster.status.nics) {
                this.inputNICs = broadcaster.status.nics || [{ name: "Any", value: "", nic: "" }];
                if (!this.source.input_nic) this.source.input_nic = "";
            } else {
                this.inputNICs = [{ name: "Any", value: "", nic: "" }];
                this.source.input_nic = "";
            }
        } else {
            this.inputNICs = [{ name: "Any", value: "", nic: "" }];
            this.source.input_nic = "";
        }
        this.targetNICLoading = false;
    }

    ngOnInit() {
        // Sources
        this.ss.refreshSources(true);
        this.sourcesSubscription = this.ss.sources.subscribe((sources: Source[]) => {
            this.sources = sources;
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.sourcesSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;
        const sourceModel = {
            name: this.isEdit ? undefined : this.nameControl.value,
            broadcaster_cluster_id: this.source.broadcaster_cluster_id,
            target_broadcaster_id: this.source.target_broadcaster_id,
            monitor_pids_change: this.source.monitor_pids_change ? 1 : 0,
            content_analysis: this.source.content_analysis ? 1 : 0,
            tr101_analysis: this.source.tr101_analysis ? 1 : 0,
            monitor_cei608708_cc: this.source.monitor_cei608708_cc ? 1 : 0,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.source.alertingProfile.id,
            password: "",
            encryption: "none",
            encryption_key: "",
            allow_outputs: this.source.allow_outputs ? 1 : 0,
            outputs_password: this.source.outputs_password || "",
            pid_mapping_profile_id: this.source.pid_mapping_profile_id,
            source_id: this.source.source_id,
            protocol: "pid_map",
            report_scte_warnings: this.source.report_scte_warnings,
            process_scte_reports: this.source.process_scte_reports,
            disable_autopull: this.source.disable_autopull,
            autopull_latency: this.source.autopull_latency,
            webrtc_mode: this.source.webrtc_mode,
            location: this.source.location.address,
            muted: this.source.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.source.is_enabled,
            // webrtc_thumbnail: this.source.webrtc_thumbnail
            billing_code: this.source.billing_code,
            billing_password: this.source.billing_password,
            autopull_billing_code: this.source.autopull_billing_code,
            autopull_billing_password: this.source.autopull_billing_password,
            autopull_mtu: this.source.autopull_mtu,
            input_nic: this.source.input_nic && this.source.input_nic !== "" ? this.source.input_nic : null,
            bind_input_device: this.source.bind_input_device ? 1 : 0,
            freeze_detection_timeout_sec: this.source.freeze_detection_timeout_sec,
            blank_detection_timeout_sec: this.source.blank_detection_timeout_sec,
            hide_thumbnail: this.source.hide_thumbnail,
            enable_scte35_insertion: this.source.enable_scte35_insertion
        };

        if (this.isEdit) {
            // filter only relevant bonding fields to prevent unneccesary updates
            this.existingSource.bondedLinks = this.existingSource.bondedLinks?.map(l =>
                _.extend({
                    nic_ip: l.nic_ip,
                    device: l.device,
                    max_bitrate: l.max_bitrate,
                    backup: l.backup
                })
            );
            const changedData = this.sharedService.getZixiObjDiff(sourceModel, this.existingSource, []);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedSource = await this.ss.updateSource(this.source, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedSource;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "SOURCE",
                        async () => {
                            const updateAndRestartSource = await this.ss.updateSource(this.source, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartSource) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart mapping source", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                            } else this.saving = false;
                        },
                        this.source.name
                    );
                    this.saving = false;
                } else if (updatedSource) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update mapping source", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
            }
        } else {
            const result = await this.ss.addSource(sourceModel);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create pid mapping source");
                this.router.navigate(urlBuilder.getRegularSourceUrl(result.id, result.name));
            } else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(urlBuilder.getRegularSourceUrl(this.existingSource.id, this.existingSource.name));
        else this.router.navigate([Constants.urls.sources]);
    }

    back() {
        this.router.navigate([Constants.urls.sources, "new"]);
    }

    done() {
        this.router.navigate([Constants.urls.sources]);
    }
}

import { Component, Input } from "@angular/core";
import { MediaConnectSource } from "../../../../models/shared";
import { DeviceDetectorService } from "ngx-device-detector";
import { Constants } from "src/app/constants/constants";

@Component({
    selector: "app-mc-source-stream",
    templateUrl: "./mc-source-stream.component.html"
})
export class MediaConnectSourceStreamComponent {
    @Input() source: MediaConnectSource;
    @Input() canEdit: boolean;
    canVLC = false;
    showOverlay = true;

    urls = Constants.urls;
    constants = Constants;

    constructor(private deviceService: DeviceDetectorService) {
        this.canVLC = ["windows", "mac"].includes(this.deviceService.os.toLocaleLowerCase());
    }
}

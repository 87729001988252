<div class="details-form d-flex flex-wrap flex-row grid-gap-3">
    <div class="flex-1 flex-basis-min">
        <h4 translate>PREVIEW</h4>
        <app-source-thumbnail-from-service
            [source]="source"
            [info]="false"
            [bordered]="true"
            [overlay]="showOverlay"
            [allowLivePlay]="true"
        ></app-source-thumbnail-from-service>
        <div class="d-flex flex-row mt-2">
            <!-- Play -->
            <div class="form-group flex-shrink-0 me-3" *ngIf="source.webrtc_mode !== ''">
                <div>
                    <a [routerLink]="['/' + urls.sources, source.id | uId, source?.name, 'webrtc']" target="_blank" class="btn btn-play btn-primary"
                        ><fa-icon icon="play" size="xs" class="me-1"></fa-icon>{{ "WEBRTC" | translate }}</a
                    >
                </div>
            </div>
            <div class="form-group flex-shrink-1" *ngIf="source.view_url && canVLC">
                <div>
                    <a href="/play/source/{{ source.playId }}" target="_blank" class="btn btn-play btn-primary"
                        ><fa-icon icon="play" size="xs" class="me-1"></fa-icon>{{ "VLC" | translate }}</a
                    >
                </div>
                <small>
                    ({{ "REQUIRES" | translate }} <a target="_blank" id="downloadVLC" href="http://www.videolan.org/vlc/">{{ "VLC" | translate }}</a>
                    {{ "WITH_ZIXI_PLUGIN" | translate }}
                    <a target="_blank" id="downloadWindows" href="https://portal.zixi.com/free/zixi_vlc_plugin-win-latest.zip">{{ "WINDOWS" | translate }}</a
                    >, <a target="_blank" id="downloadMac" href="https://portal.zixi.com/free/zixi_vlc_plugin-mac-latest.tar.gz">{{ "MAC" | translate }}</a
                    >)
                </small>
            </div>
            <div class="form-group flex-shrink-0 ms-auto">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="show_overlay" name="show_overlay" [(ngModel)]="showOverlay" />
                    <label class="form-check-label" for="show_overlay">{{ "SHOW_OVERLAY" | translate }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-1 flex-basis-min">
        <div class="d-flex">
            <h4 translate>STREAM_INFORMATION</h4>
            <div class="form-group flex-shrink-0 mb-0 ms-auto">
                <div class="form-check">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        id="expand_programs"
                        name="expand_programs"
                        [(ngModel)]="expandPrograms"
                        (ngModelChange)="saveExpandPrograms()"
                    />
                    <label class="form-check-label" for="expand_programs">{{ "START_EXPANDED" | translate }}</label>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <app-source-stream-info
                    [source]="source"
                    (resetFunc)="resetTR101()"
                    identifier="newpage"
                    [expandPrograms]="expandPrograms"
                ></app-source-stream-info>
            </div>
        </div>
    </div>
</div>

<!-- SCTE35 Insertion -->
<div class="form-group" *ngIf="allowScte35Insert">
    <div class="form-check">
        <input
            type="checkbox"
            class="form-check-input"
            id="enable_scte35_insertion"
            name="enable_scte35_insertion"
            [(ngModel)]="source.enable_scte35_insertion"
        />
        <label class="form-check-label" for="enable_scte35_insertion">{{ "ENABLE_SCTE35_INSERTION" | translate }} </label>
    </div>
</div>
<!-- Public Output -->
<div class="form-group">
    <label for="public_output">{{ "PUBLIC_OUTPUT" | translate }}</label>
    <div class="input-group align-items-center">
        <div class="input-group-prepend">
            <div class="input-group">
                <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" id="public_output_check" name="public_output_check" [(ngModel)]="source.allow_outputs" />
                    <label class="form-check-label" for="public_output_check">{{ "ALLOW" | translate }}</label>
                </div>
            </div>
        </div>
        <div class="input-group-div">
            <input
                type="password"
                autocomplete="cc-csc"
                id="outputs_password"
                name="outputs_password"
                placeholder="{{ 'PASSWORD' | translate }}"
                class="form-control"
                [disabled]="!source.allow_outputs"
                [(ngModel)]="source.outputs_password"
            />
        </div>
    </div>
</div>
<!--Content Analysis -->
<fieldset class="form-group">
    <legend class="form-check-label" for="content_analysis">
        {{ "CONTENT_ANALYSIS" | translate }}<fa-icon icon="info-circle" [ngbTooltip]="CAContent" triggers="hover click" [closeDelay]="500"></fa-icon
        ><ng-template #CAContent
            ><p class="mb-0">
                {{ "TOOLTIP.SOURCE_CONTENT_ANALYSIS" | translate }}
            </p></ng-template
        >
    </legend>
    <mat-button-toggle-group
        name="content_analysis"
        aria-label="content analysis configuration"
        [(ngModel)]="contentAnalysisInput"
        (change)="contentAnalysisInputChanges()"
    >
        <mat-button-toggle [value]="CONTENT_ANALYSIS.FULL">{{ "FULL" | translate }}</mat-button-toggle>
        <mat-button-toggle [value]="CONTENT_ANALYSIS.TR101_ONLY">{{ "TR101_ONLY" | translate }}</mat-button-toggle>
        <mat-button-toggle [value]="CONTENT_ANALYSIS.NONE">{{ "NONE" | translate }}</mat-button-toggle>
    </mat-button-toggle-group>
</fieldset>
<!-- Hide Thumbnail  -->
<div class="form-group">
    <div class="form-check">
        <input type="checkbox" class="form-check-input" id="hide_thumbnail" name="hide_thumbnail" [(ngModel)]="source.hide_thumbnail" />
        <label class="form-check-label" for="hide_thumbnail">{{ "HIDE" | translate }} {{ "THUMBNAIL" | translate }}</label>
    </div>
</div>
<!-- Monitor CEA-608/CEA-708 Closed Captions -->
<div class="form-group">
    <div class="form-check">
        <input
            type="checkbox"
            class="form-check-input"
            id="monitor_cei608708_cc"
            name="monitor_cei608708_cc"
            [(ngModel)]="source.monitor_cei608708_cc"
            [disabled]="!source.content_analysis"
        />
        <label class="form-check-label" for="monitor_cei608708_cc">{{ "MONITOR_608_708_CLOSED_CAPTIONS" | translate }} </label>
    </div>
    <small>{{ "VERSION_14_4_REQUIRED" | translate }}</small>
</div>
<!-- Disable Auto-Pull -->
<div class="form-group">
    <div class="form-check">
        <input type="checkbox" class="form-check-input" id="disable_autopull" name="disable_autopull" [(ngModel)]="source.disable_autopull" />
        <label class="form-check-label" for="disable_autopull">{{ "DISABLE_AUTOPULL" | translate }}</label>
    </div>
    <small>{{ "DISABLE_AUTOPULL_WARNING" | translate }}</small>
    <br *ngIf="isEdit" /><small *ngIf="isEdit">{{ "DISABLE_AUTOPULL_EDIT_WARNING" | translate }}</small>
</div>
<!-- Auto-Pull Latency -->
<div class="form-group" *ngIf="!source.disable_autopull">
    <label for="autopull_latency" [ngClass]="{ 'is-invalid': form.submitted && autopull_latency.errors }"
        >{{ "AUTO_PULL" | translate }} {{ "LATENCY" | translate }} [ms]</label
    >
    <input
        type="number"
        id="autopull_latency"
        name="autopull_latency"
        placeholder="{{ 'AUTO_PULL' | translate }} {{ 'LATENCY' | translate }}"
        class="form-control"
        min="0"
        pattern="^\d+$"
        [(ngModel)]="source.autopull_latency"
        [ngClass]="{ 'is-invalid': form.submitted && autopull_latency.errors }"
        #autopull_latency="ngModel"
    />
    <small>{{ "PULL_LATENCY_NOTE" | translate }}</small>
    <div *ngIf="autopull_latency.invalid" class="invalid-feedback">
        <div *ngIf="autopull_latency.errors.required">{{ "AUTO_PULL" | translate }} {{ "LATENCY" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
        <div *ngIf="autopull_latency.errors.pattern">
            {{ "AUTO_PULL" | translate }} {{ "LATENCY" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
        </div>
    </div>
</div>
<!-- WebRTC -->
<fieldset class="form-group">
    <legend for="input-webrtc-mode">{{ "WEBRTC_PLAYBACK" | translate }}</legend>
    <mat-button-toggle-group name="input-webrtc-mode" aria-label="input webrtc mode" [(ngModel)]="source.webrtc_mode">
        <mat-button-toggle value="">{{ "DISABLED" | translate }}</mat-button-toggle>
        <mat-button-toggle value="auto">{{ "AUTO" | translate }}</mat-button-toggle>
        <mat-button-toggle value="1080p">{{ "1080P" | translate }}</mat-button-toggle>
        <mat-button-toggle value="720p">{{ "720P" | translate }}</mat-button-toggle>
        <mat-button-toggle value="480p">{{ "480P" | translate }}</mat-button-toggle>
        <mat-button-toggle value="360p">{{ "360P" | translate }}</mat-button-toggle>
        <mat-button-toggle value="passthrough" *ngIf="showMoreWebRTCOptions">{{ "PASS-THROUGH" | translate }}</mat-button-toggle>
    </mat-button-toggle-group>
    <div class="more-option-btn mb-3 ms-2" (click)="showMoreWebRTCOptions = !showMoreWebRTCOptions">
        {{ showMoreWebRTCOptions ? ("HIDE_OPTIONS" | translate) : ("MORE_OPTIONS" | translate) }}
    </div>
    <br />
    <small>{{ "WEBRTC_WARNING" | translate }}</small>
    <br />
    <small>{{ "WEBRTC_AUTO_WARNING" | translate }}</small>
    <br />
    <small>{{ "WEBRTC_PT_WARNING" | translate }}</small>
</fieldset>

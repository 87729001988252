<div *ngIf="!source?.status?.tr101?.errors?.length">
    <p>{{ "CONTENT_ANALYSIS_INFO_NOT_AVAIL" | translate }}</p>
</div>

<div *ngIf="source?.status?.tr101?.errors?.length">
    <!-- P1 -->
    <div>
        <h4 title="{{ 'PRIORITY' | translate }} 1">{{ "PRIORITY" | translate }} 1</h4>
        <div class="table-responsive mb-3">
            <table class="table table-sm bordered mb-0">
                <thead>
                    <tr>
                        <th scope="col" class="w-25px"></th>
                        <th scope="col">{{ "NAME" | translate }}</th>
                        <th scope="col" class="w-50px">{{ "COUNT" | translate }}</th>
                        <th scope="col">{{ "TIME" | translate }}</th>
                        <th scope="col">{{ "DESCRIPTION" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let error of source.status.tr101.errors | filter : 'type' : 'p1'">
                        <td>
                            <fa-icon *ngIf="error.count === 0" icon="check-circle" size="md" class="status-good"></fa-icon>
                            <fa-icon *ngIf="error.count > 0" icon="minus-circle" size="md" class="status-bad"></fa-icon>
                        </td>
                        <td class="w-160px ellipsis" title="{{ error.name | desnake }}">{{ error.name | desnake }}</td>
                        <td>{{ error.count }}</td>
                        <td class="maxw-180px ellipsis">{{ error.time | date : "MMM d, y, h:mm:ss a" }}</td>
                        <td cclass="maxw-400px ellipsis" title="{{ error.count > 0 ? error.description : '-' }}">
                            {{ error.count > 0 ? error.description : "-" }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- P2 -->
    <div>
        <h4 title="{{ 'PRIORITY' | translate }} 2">{{ "PRIORITY" | translate }} 2</h4>
        <div class="table-responsive mb-3">
            <table class="table table-sm bordered mb-0">
                <thead>
                    <tr>
                        <th scope="col" class="w-25px"></th>
                        <th scope="col">{{ "NAME" | translate }}</th>
                        <th scope="col" class="w-50px">{{ "COUNT" | translate }}</th>
                        <th scope="col">{{ "TIME" | translate }}</th>
                        <th scope="col">{{ "DESCRIPTION" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let error of source.status.tr101.errors | filter : 'type' : 'p2'">
                        <td>
                            <fa-icon *ngIf="error.count === 0" icon="check-circle" size="md" class="status-good"></fa-icon>
                            <fa-icon *ngIf="error.count > 0" icon="minus-circle" size="md" class="status-bad"></fa-icon>
                        </td>
                        <td class="w-160px ellipsis" title="{{ error.name | desnake }}">{{ error.name | desnake }}</td>
                        <td>{{ error.count }}</td>
                        <td class="maxw-180px ellipsis">{{ error.time | date : "MMM d, y, h:mm:ss a" }}</td>
                        <td class="maxw-400px ellipsis" title="{{ error.count > 0 ? error.description : '-' }}">
                            {{ error.count > 0 ? error.description : "-" }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SourcesService } from "../sources.service";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { SharedService } from "../../../services/shared.service";

import { Source, BroadcasterInput } from "../../../models/shared";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleCasePipe } from "@angular/common";
import { TitleService } from "../../../services/title.service";
import { TranslateService } from "@ngx-translate/core";
import { UsersService } from "../../account-management/users/users.service";
import { ControlContainer, UntypedFormControl, NgForm, Validators, FormControl } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";
import { PidMappingsService } from "../../pid-mappings/pid-mappings.service";

@Component({
    selector: "app-source-form-file",
    templateUrl: "./source-form.component.html",
    providers: [TitleCasePipe],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceFormFileComponent implements OnInit, OnDestroy {
    source: Source;
    sources: Source[];
    sourceName: string;
    sourceNames: string[];
    sourceId: number;

    existingSource: Source;

    action: string;
    loading = true;
    saving = false;
    constants = Constants;
    showAdvanced = false;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    startDisabled = false;

    broadcasterDetailsLoading = false;
    broadcasterInputs: BroadcasterInput[] = [];
    selectedBroadcasterInputs: BroadcasterInput[] = [];

    maxBitrates = Constants.maxBitrates;
    broadcasterInputsFilter: string;

    accountTracerouteHistory: boolean;

    page = 1;
    pageSize = 10;

    MAX_INPUTS = 25;

    private sourcesSubscription: Subscription;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new FormControl<string>("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name)
    ]);

    pidProfileControl = new FormControl<number>({ value: null, disabled: this.saving }, []);

    pidMappingProfiles$ = this.pidMappingService.pidMappingProfiles;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ss: SourcesService,
        private broadcastersService: BroadcastersService,
        private sharedService: SharedService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe,
        private pidMappingService: PidMappingsService,
        private userService: UsersService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.sourceName = params.get("name");
            this.sourceId = urlBuilder.decode(params.get("sourceId"));
            this.action = params.get("action");
            if (this.sourceName && this.sourceId) {
                this.source = Object.assign({}, this.ss.getCachedSource(this.sourceName, null, this.sourceId));
                this.existingSource = _.cloneDeep(this.source);

                // Check if source found in cache, if not get sources and source
                if (this.sharedService.isEmptyObject(this.source) || !this.source.hasFullDetails) {
                    this.ss
                        .refreshSources(true)
                        .pipe(take(1))
                        .subscribe(async () => {
                            this.source = this.ss.getCachedSource(this.sourceName, null, this.sourceId);
                            this.existingSource = _.cloneDeep(this.source);

                            await this.ss.refreshSource(this.source).toPromise();

                            this.source = Object.assign(
                                {},
                                this.ss.getCachedSource(this.sourceName, null, this.sourceId)
                            );
                            this.existingSource = _.cloneDeep(this.source);

                            this.prepForm();
                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        });
    }

    prepForm() {
        if (this.action) {
            this.pidProfileControl.setValue(this.source.pid_mapping_profile_id);
            this.tagsControl.setValue(this.source.resourceTags);
            if (this.action === "edit") {
                this.isEdit = true;
                this.nameControl.setValue(this.source.name);
            } else if (this.action === "clone") {
                this.isClone = true;
                this.source.name = "";
                this.source.muted = this.source.active_mute ? 1 : 0;
            }

            if (this.source) {
                if (this.source.broadcaster_id) {
                    this.getBroadcasterDetails(this.source.broadcaster_id);
                }
            }
        }

        if (!this.source && !this.isEdit) {
            this.source = new Source();
            this.source.type = "monitor_only";
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle(
            this.translate.instant("SOURCE") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.source && this.source.name ? this.source.name : "")
        );
    }

    resetForm() {
        // Source
        this.source.mediaconnect_mode = "pull";
        this.source.latency = 4000;
        this.source.content_analysis = 1;
        this.source.tr101_analysis = 1;
        this.source.monitor_pids_change = 0;
        this.source.traceroute_history = 0;
        this.source.output_nic = "";
        this.tagsControl.setValue([]);
        this.source.broadcaster_cluster_id = null;
        this.source.broadcaster_id = null;
        this.source.feeder_id = null;
        this.source.transcode_source_id = null;
        this.nameControl.setValue(null);
        this.source.allow_outputs = 0;
        this.source.outputs_password = null;
        this.source.transcode_profile_id = null;
        this.source.transcode_cbr_kbps = null;
        this.source.webrtc_mode = "";
        this.source.location = {};
        this.source.autopull_latency = null;
        this.source.input_id = "";
        this.source.billing_code = null;
        this.source.billing_password = null;
        this.source.autopull_billing_code = null;
        this.source.autopull_billing_password = null;
        this.source.autopull_mtu = null;
        this.source.protocol = "http_file";
        this.source.url = "";
        this.source.freeze_detection_timeout_sec = 10;
        this.source.blank_detection_timeout_sec = 10;

        // this.source.webrtc_thumbnail = 0;
        // UI
        this.broadcasterInputs = [];
        this.selectedBroadcasterInputs = [];
    }

    getClusterSourceNames(id: number) {
        const filteredSources = _.filter(this.sources, source => id === source.broadcaster_cluster_id);
        let filteredSourceNames = _.map(filteredSources, "name");

        if (this.isEdit) filteredSourceNames = _.without(filteredSourceNames, this.source.name);

        this.sourceNames = filteredSourceNames;
    }

    async getBroadcasterDetails(id: number, clearConfig?: boolean) {
        this.broadcasterInputs = [];
        if (clearConfig) {
            this.source.output_nic = "";
            this.selectedBroadcasterInputs = [];
        }
        this.broadcasterDetailsLoading = true;
        if (!id) {
            this.broadcasterDetailsLoading = false;
            return;
        }
        const broadcaster = await this.broadcastersService.refreshBroadcaster(id, true).toPromise();

        if (broadcaster) {
            this.source.broadcaster_cluster_id = broadcaster.broadcaster_cluster_id;

            if (broadcaster.status && broadcaster.status.inputs) {
                this.broadcasterInputs = this.sharedService.sort(broadcaster.status.inputs || [], "id", "asc");
            } else {
                this.broadcasterInputs = [];
            }
        } else {
            this.broadcasterInputs = [];
        }

        // reset selected input if it no longer exists on the bx
        if (!this.broadcasterInputs.find(input => input.id === this.source.input_id)) this.source.input_id = null;

        this.broadcasterDetailsLoading = false;
    }

    selectBroadcasterInput(input: BroadcasterInput) {
        if (this.selectedBroadcasterInputs.length >= this.MAX_INPUTS) return;

        this.selectedBroadcasterInputs.push(input);
        this.broadcasterInputs = this.broadcasterInputs.filter(i => {
            return i.id !== input.id;
        });
    }

    deselectBroadcasterInput(input: BroadcasterInput) {
        this.broadcasterInputs.push(input);
        this.broadcasterInputs = this.sharedService.sort(this.broadcasterInputs || [], "id", "asc");
        this.selectedBroadcasterInputs = this.selectedBroadcasterInputs.filter(i => {
            return i.id !== input.id;
        });
    }

    ngOnInit() {
        // PID Mapping Profiles
        this.pidMappingService.refreshPIDMappingProfiles();
        // Sources
        this.ss.refreshSources(true);
        this.sourcesSubscription = this.ss.sources.subscribe((sources: Source[]) => {
            this.sources = sources;
        });

        this.userService.isTracerouteHistory.pipe(take(1)).subscribe(bool => {
            this.accountTracerouteHistory = bool;
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.sourcesSubscription.unsubscribe();
    }

    async onSubmit() {
        if (
            (this.source.protocol === "file" && (this.source.input_id == null || this.source.input_id === "")) ||
            (this.source.protocol === "http_file" && (this.source.url == null || this.source.url === ""))
        )
            return;

        this.saving = true;
        const newSource = {
            type: "file",
            protocol: this.source.protocol,
            name: this.isEdit ? undefined : this.nameControl.value,
            broadcaster_cluster_id: this.source.broadcaster_cluster_id,
            autobond: 0,
            feeder_id: null,
            broadcaster_id: null,
            target_broadcaster_id: this.source.target_broadcaster_id,
            input_id: this.source.input_id,
            monitor_pids_change: 0,
            traceroute_history: 0,
            content_analysis: this.source.content_analysis ? 1 : 0,
            tr101_analysis: this.source.tr101_analysis ? 1 : 0,
            monitor_cei608708_cc: this.source.monitor_cei608708_cc ? 1 : 0,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.source.alertingProfile.id,
            password: "",
            encryption: "none",
            encryption_key: "",
            allow_outputs: this.source.allow_outputs ? 1 : 0,
            outputs_password: this.source.outputs_password || "",
            monitor_only: 0,
            output_nic: "",
            bondedLinks: [],
            report_scte_warnings: false,
            process_scte_reports: false,
            location: this.source.location.address,
            disable_autopull: this.source.disable_autopull,
            autopull_latency: this.source.autopull_latency,
            webrtc_mode: this.source.webrtc_mode,
            muted: this.source.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.source.is_enabled,
            // webrtc_thumbnail: this.source.webrtc_thumbnail
            billing_code: this.source.billing_code,
            billing_password: this.source.billing_password,
            autopull_billing_code: this.source.autopull_billing_code,
            autopull_billing_password: this.source.autopull_billing_password,
            autopull_mtu: this.source.autopull_mtu,
            url: this.source.url,
            freeze_detection_timeout_sec: this.source.freeze_detection_timeout_sec,
            blank_detection_timeout_sec: this.source.blank_detection_timeout_sec,
            hide_thumbnail: this.source.hide_thumbnail,
            enable_scte35_insertion: this.source.enable_scte35_insertion,
            pid_mapping_profile_id: this.pidProfileControl.value
        };

        if (this.isEdit) {
            // filter only relevant bonding fields to prevent unneccesary updates
            this.existingSource.bondedLinks = this.existingSource.bondedLinks?.map(l =>
                _.extend({
                    nic_ip: l.nic_ip,
                    device: l.device,
                    max_bitrate: l.max_bitrate,
                    backup: l.backup
                })
            );
            const changedData = this.sharedService.getZixiObjDiff(newSource, this.existingSource, []);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedSource = await this.ss.updateSource(this.source, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedSource;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "SOURCE",
                        async () => {
                            const updateAndRestartSource = await this.ss.updateSource(this.source, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartSource) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart file source", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                            } else this.saving = false;
                        },
                        this.source.name
                    );
                    this.saving = false;
                } else if (updatedSource) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update file source", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
            }
        } else {
            const result = await this.ss.addSource(newSource);
            if (result) {
                this.mixpanelService.sendEvent("create file source");
                this.router.navigate(urlBuilder.getRegularSourceUrl(result.id, result.name));
            } else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit)
            this.router.navigate(urlBuilder.getRegularSourceUrl(this.existingSource.id, this.existingSource.name));
        else this.router.navigate([Constants.urls.sources]);
    }

    back() {
        this.router.navigate([Constants.urls.sources, "new"]);
    }

    done() {
        this.router.navigate([Constants.urls.sources]);
    }
}

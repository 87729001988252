import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ControlContainer, UntypedFormControl, NgForm, Validators, FormControl } from "@angular/forms";
import { TitleCasePipe } from "@angular/common";
import { SourcesService } from "../sources.service";
import { SharedService } from "../../../services/shared.service";
import { map, take } from "rxjs/operators";
import * as _ from "lodash";

import { Source } from "../../../models/shared";
import { Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { urlBuilder } from "@zixi/shared-utils";
import { Constants } from "../../../constants/constants";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { ModalService } from "src/app/components/shared/modals/modal.service";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-source-form-demux",
    templateUrl: "./source-form.component.html",
    providers: [TitleCasePipe],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceFormDemuxComponent implements OnInit {
    source: Source;
    sourceName: string;
    sourceId: number;
    inputSourceId: number;

    action: string;
    loading = true;
    saving = false;

    isEdit = false;
    isClone = false;
    startDisabled = false;
    isMuted = false;

    demux_program_number: number;
    source_programs_numbers: { value: number; name: string }[] | null;
    loading_source_programs = false;
    existingSource: Source;

    targetBXsLoading = false;
    targetBXs = [];
    constants = Constants;
    maxBitrates = Constants.maxBitrates;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    pcrIntervalMsControl = new FormControl<100 | 40>({ value: 100, disabled: false });
    pcrIntervalMsOptions = [
        { name: 40, value: 40 },
        { name: 100, value: 100 }
    ];

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private broadcastersService = inject(BroadcastersService);
    private modalService = inject(ModalService);
    private translate = inject(TranslateService);
    private ss = inject(SourcesService);
    private sharedService = inject(SharedService);
    private titleService = inject(TitleService);
    private titlecasePipe = inject(TitleCasePipe);
    private mixpanelService = inject(MixpanelService);
    private location = inject(Location);

    async ngOnInit() {
        const params = this.route.snapshot.params;
        this.sourceName = params.name;
        this.action = params.action;
        this.sourceId = urlBuilder.decode(params.sourceId);
        if (this.action === "edit") this.isEdit = true;
        if (this.action === "clone") this.isClone = true;
        if (this.sourceName && this.sourceId) {
            this.source = Object.assign({}, this.ss.getCachedSource(this.sourceName, null, this.sourceId));
            this.existingSource = _.cloneDeep(this.source);

            // Check if source found in cache, if not get sources and source
            if (this.sharedService.isEmptyObject(this.source) || !this.source.hasFullDetails) {
                this.ss
                    .refreshSources(true)
                    .pipe(take(1))
                    .subscribe(async () => {
                        this.source = this.ss.getCachedSource(this.sourceName, null, this.sourceId);
                        this.existingSource = _.cloneDeep(this.source);

                        await this.ss.refreshSource(this.source).toPromise();

                        this.source = Object.assign({}, this.ss.getCachedSource(this.sourceName, null, this.sourceId));
                        this.existingSource = _.cloneDeep(this.source);

                        this.prepForm();
                        this.loading = false;
                    });
            } else {
                this.loading = false;
            }
        } else {
            this.loading = false;
        }
        // Sources
        this.ss.refreshSources(true);

        this.prepForm();
    }

    prepForm() {
        if (this.action) {
            this.tagsControl.setValue(this.source.resourceTags);
            if (this.isEdit) this.nameControl.setValue(this.source.name);
            if (this.isClone) {
                this.nameControl.setValue("");
                this.source.muted = this.source.active_mute ? 1 : 0;
            }
            if (this.isEdit || this.isClone) {
                if (this.source.pcr_interval_ms) this.pcrIntervalMsControl.setValue(this.source.pcr_interval_ms);
                if (!this.inputSourceId && this.source.source_id) this.inputSourceId = this.source.source_id;
                if (this.source.broadcaster_cluster_id) this.getTargetBroadcasters(this.source.broadcaster_cluster_id);
                if (this.inputSourceId) this.demuxSourceChange();
                this.demux_program_number = this.source.demux_program_number;
                this.source.target_bitrate = this.source.target_bitrate ? this.source.target_bitrate / 1000 : null;

                this.targetBitrateUpdate();
            }
        }

        if (!this.source && !this.isClone && !this.isEdit) {
            this.source = new Source();
            this.source.type = "demux";
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle(
            this.translate.instant("SOURCE") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.source && this.source.name ? this.source.name : "")
        );
    }

    resetForm() {
        // Source
        this.source.content_analysis = 1;
        this.source.tr101_analysis = 1;
        this.tagsControl.setValue([]);
        this.nameControl.setValue(null);
        this.pcrIntervalMsControl.setValue(100);
        this.source.location = {};
        this.source.allow_outputs = 0;
        this.source.outputs_password = null;
        this.source.pid_mapping_profile_id = null;
        this.source.webrtc_mode = "";
        this.source.location = {};
        this.source.autopull_latency = null;
        this.source.billing_code = null;
        this.source.billing_password = null;
        this.source.autopull_billing_code = null;
        this.source.autopull_billing_password = null;
        this.source.autopull_mtu = null;
        this.source.freeze_detection_timeout_sec = 10;
        this.source.blank_detection_timeout_sec = 10;
        this.source.target_bitrate = null;
        this.source.max_bitrate = 0;
        this.targetBitrateUpdate();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            protocol: "demux",
            type: "demux",
            name: this.nameControl.value,
            broadcaster_cluster_id: this.source.broadcaster_cluster_id,
            source_id: this.inputSourceId,
            demux_program_number: this.demux_program_number,
            target_broadcaster_id: this.source.target_broadcaster_id,
            resource_tag_ids: this.tagsControl.value.map(i => i.id),
            alerting_profile_id: this.source.alertingProfile.id,
            location: this.source.location.address,
            is_enabled: !this.startDisabled,
            report_scte_warnings: this.source.report_scte_warnings,
            process_scte_reports: this.source.process_scte_reports,
            disable_autopull: this.source.disable_autopull,
            autopull_latency: this.source.autopull_latency,
            webrtc_mode: this.source.webrtc_mode,
            muted: this.isMuted,
            billing_code: this.source.billing_code,
            billing_password: this.source.billing_password,
            autopull_billing_code: this.source.autopull_billing_code,
            autopull_billing_password: this.source.autopull_billing_password,
            autopull_mtu: this.source.autopull_mtu,
            input_nic: this.source.input_nic && this.source.input_nic !== "" ? this.source.input_nic : null,
            bind_input_device: this.source.bind_input_device ? 1 : 0,
            freeze_detection_timeout_sec: this.source.freeze_detection_timeout_sec,
            blank_detection_timeout_sec: this.source.blank_detection_timeout_sec,
            allow_outputs: !!this.source.allow_outputs,
            outputs_password: !!this.source.allow_outputs ? this.source.outputs_password : undefined,
            content_analysis: this.source.content_analysis,
            tr101_analysis: this.source.tr101_analysis ? 1 : 0,
            monitor_cei608708_cc: this.source.monitor_cei608708_cc,
            target_bitrate: this.source.target_bitrate ? this.source.target_bitrate * 1000 : null,
            max_bitrate: this.source.max_bitrate,
            hide_thumbnail: this.source.hide_thumbnail,
            enable_scte35_insertion: this.source.enable_scte35_insertion,
            pcr_interval_ms: this.pcrIntervalMsControl.value ? this.pcrIntervalMsControl.value : undefined
        };

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(
                model,
                {
                    ...this.existingSource,
                    location: this.existingSource.location.address,
                    outputs_password: undefined,
                    allow_outputs: Boolean(this.existingSource.allow_outputs)
                },
                ["is_enabled", "muted"]
            );
            const isEmptyObject = this.sharedService.isEmptyObject(changedData);
            if (!isEmptyObject) {
                const updatedSource = await this.ss.updateSource(this.source, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedSource;

                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "SOURCE",
                        async () => {
                            const updateAndRestartSource = await this.ss.updateSource(this.source, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartSource) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart demux source", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                            } else this.saving = false;
                        },
                        this.source.name
                    );
                    this.saving = false;
                } else if (updatedSource) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update demux source", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
            }
        } else {
            const result = await this.ss.addSource(model);
            if (result) {
                this.mixpanelService.sendEvent("create demux source");
                this.router.navigate(urlBuilder.getRegularSourceUrl(result.id, result.name));
            } else this.saving = false;
        }
    }

    async demuxSourceChange() {
        this.source_programs_numbers = null;
        this.loading_source_programs = true;
        this.demux_program_number = null;

        const res = this.ss.refreshSource(this.inputSourceId);
        res.pipe(take(1)).subscribe(source => {
            if (source.status) {
                this.source_programs_numbers = source.status.tr101.programs.map(program => {
                    let name = "Program #" + program.general.number;
                    if (program.general.name) name += " (" + program.general.name + ")";
                    if (program.general.provider) name += " (" + program.general.provider + ")";
                    return {
                        value: program.general.number,
                        name: name
                    };
                });
            } else {
                this.source_programs_numbers = null;
            }
            this.loading_source_programs = false;
        });
    }

    async getTargetBroadcasters(id: number) {
        this.targetBXsLoading = true;
        this.targetBXs = await firstValueFrom(
            this.broadcastersService.refreshBroadcasters(id, true).pipe(
                map(bxs =>
                    bxs.map(broadcaster => ({
                        id: broadcaster.id,
                        name: broadcaster.name,
                        type: "broadcaster",
                        generalStatus: broadcaster.generalStatus
                    }))
                )
            )
        );
        this.targetBXsLoading = false;
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(urlBuilder.getRegularSourceUrl(this.existingSource.id, this.existingSource.name));
        else this.router.navigate([Constants.urls.sources]);
    }

    back() {
        this.location.back();
    }

    targetBitrateUpdate() {
        if (this.source.target_bitrate) {
            if (this.pcrIntervalMsControl.disabled) {
                this.pcrIntervalMsControl.setValue(100);
                this.pcrIntervalMsControl.enable();
            }
            this.maxBitrates = [{ name: "Automatically Computed", value: 100000000 }];
            this.source.max_bitrate = 100000000;
        } else {
            this.pcrIntervalMsControl.disable();
            this.pcrIntervalMsControl.setValue(null);
            this.maxBitrates = this.constants.maxBitrates;
            if (!this.source.max_bitrate)
                this.source.max_bitrate = this.maxBitrates[2]?.value ?? this.maxBitrates[0]?.value;
        }
    }
}

import { TitleCasePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";

import { Constants } from "../../../constants/constants";
import { ControlContainer, UntypedFormControl, NgForm, Validators } from "@angular/forms";
import { SharedService } from "src/app/services/shared.service";
import { Tag } from "src/app/models/shared";

@Component({
    selector: "app-source-form",
    templateUrl: "./source-form.component.html",
    providers: [TitleCasePipe],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceFormComponent implements OnInit {
    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name)
    ]);

    isZixiUser = false;
    constants = Constants;

    mediaconnectResourceTags: Tag[] = [];
    sourceResourceTags: Tag[] = [];
    pidResourceTags: Tag[] = [];

    constructor(
        private router: Router,
        private translate: TranslateService,
        private titleService: TitleService,
        private sharedService: SharedService
    ) {
        // Set Title
        this.titleService.setTitle(this.translate.instant("SOURCE") + " - New");
    }

    async ngOnInit() {
        // resourceTags
        const sourceTags = this.sharedService.getResourceTagsByType("source").toPromise();
        const pidTags = this.sharedService.getResourceTagsByType("pid_mapping_profiles").toPromise();
        const mediaconnectTags = this.sharedService.getResourceTagsByType("mediaconnect_sources").toPromise();

        await Promise.all([sourceTags, pidTags, mediaconnectTags]);

        sourceTags.then((tags: Tag[]) => {
            this.sourceResourceTags = tags;
        });

        pidTags.then((tags: Tag[]) => {
            this.pidResourceTags = tags;
        });

        mediaconnectTags.then((tags: Tag[]) => {
            this.mediaconnectResourceTags = tags;
        });
    }

    goto(type: string) {
        this.router.navigate([Constants.urls.sources, type, "new"]);
    }

    cancel() {
        this.router.navigate([Constants.urls.sources]);
    }
}

import { Component, Input } from "@angular/core";
import { Source } from "../../../../models/shared";

@Component({
    selector: "app-source-bonding-info",
    templateUrl: "./source-bonding-info.component.html"
})
export class SourceBondingInfoComponent {
    @Input() source: Source;
    @Input() canEdit: boolean;
    @Input() bordered?: boolean;

    pwVisible = false;
    canVLC: boolean;
}

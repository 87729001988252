import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { SourcesService } from "../sources.service";
import { MediaConnectSourcesService } from "../mediaconnect-sources.service";
import { Source, MediaConnectSource } from "../../../models/shared";

@Injectable({
    providedIn: "root"
})
export class SourceListResolverService implements Resolve<Source[]> {
    constructor(private ss: SourcesService) {}

    resolve(): Observable<Source[]> | Observable<never> {
        return new Observable((observe: Subscriber<Source[]>) => {
            this.ss.loadSourcesSuperFast().subscribe((sources: Source[]) => {
                observe.next(sources);
                observe.complete();
            });
        });
    }
}

@Injectable({
    providedIn: "root"
})
export class MediaConnectSourceListResolverService implements Resolve<MediaConnectSource[]> {
    constructor(private ss: MediaConnectSourcesService) {}

    resolve(): Observable<MediaConnectSource[]> | Observable<never> {
        return new Observable((observe: Subscriber<MediaConnectSource[]>) => {
            this.ss.loadMediaConnectSourcesPaginated().subscribe((sources: MediaConnectSource[]) => {
                observe.next(sources);
                observe.complete();
            });
        });
    }
}

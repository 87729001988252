<div class="not-recovered-button">
    <button type="button" class="btn btn-outline-primary" (click)="explain()" [disabled]="loading">
        <span>{{ "EXPLAIN_NOT_RECOVERED" | translate }}</span>
    </button>
    <button type="button" class="btn btn-outline-primary ms-2" (click)="close()" [disabled]="loading" *ngIf="!iframeDisabled">
        <span>{{ "CLOSE" | translate }}</span>
    </button>
</div>

<div
    *ngIf="error || (!iframeDisabled && dateRangeChanged)"
    class="ps-3 pe-3"
    [ngStyle]="{
        'margin-top.px': 64
    }"
>
    <ngb-alert *ngIf="error" type="danger" [dismissible]="false">
        <strong>{{ "ERROR" | translate }}</strong> - {{ error }}
    </ngb-alert>
    <ngb-alert *ngIf="!iframeDisabled && dateRangeChanged" type="info" [dismissible]="false">
        <span [innerHTML]="'CLICK_TO_UPDATE_EXP' | translate"></span>
    </ngb-alert>
</div>
<div class="nd-viz position-relative">
    <spinner-animation *ngIf="loading"></spinner-animation>
    <div class="row" *ngIf="!iframeDisabled">
        <div class="col">
            <iframe
                #iframe
                src="../../../../../assets/iframes/nd.html"
                frameborder="no"
                scrolling="no"
                marginwidth="0"
                marginheight="0"
                hspace="0"
                vspace="0"
                class="iframe-load-animation"
                (load)="iframeOnLoad()"
            ></iframe>
        </div>
    </div>
</div>
<zx-history-graph
    [title]=""
    [hideAddIncident]="true"
    [graphUrl]="sourceHealthscoreDashboardLink"
    (from)="setFrom($event)"
    (to)="setTo($event)"
></zx-history-graph>

import { Component, OnInit, OnChanges, Input, SimpleChanges, ViewChild, ElementRef } from "@angular/core";
import { NDService } from "../../../../services/nd.service";
import { Source } from "../../../../models/shared";
import { GraphsService } from "../../../../services/graphs.service";
import moment from "moment";

@Component({
    selector: "app-source-nd",
    templateUrl: "./source-nd.component.html"
})
export class SourceNDComponent implements OnInit, OnChanges {
    @Input() source: Source;
    loading: boolean;

    iframeDisabled = true;
    dateRangeChanged = false;
    sourceHealthscoreDashboardLink: string;
    model: unknown;

    from: moment.Moment;
    to: moment.Moment;

    error: string;

    @ViewChild("iframe", { static: false }) iframe: ElementRef;

    constructor(private nd: NDService, private gs: GraphsService) {}

    ngOnInit() {
        if (this.source) this.sourceHealthscoreDashboardLink = this.gs.custom(this.gs.sourceHealth(this.source));
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.source) {
            if (changes.source.previousValue && changes.source.currentValue) {
                if (changes.source.previousValue.id !== changes.source.currentValue.id) {
                    if (this.source)
                        this.sourceHealthscoreDashboardLink = this.gs.custom(this.gs.sourceHealth(this.source));
                    return this.reset();
                }
            }
        }
    }

    iframeOnLoad() {
        this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify({ model: this.model }), "*");
        this.iframe.nativeElement.height = this.iframe.nativeElement.contentWindow.document.body.scrollHeight + "px";
    }

    close() {
        this.iframeDisabled = true;
    }

    setFrom(from: moment.Moment) {
        this.from = from;
        this.dateRangeChanged = true;
    }

    setTo(to: moment.Moment) {
        this.to = to;
        this.dateRangeChanged = true;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async explain() {
        if (!this.source && this.from && this.to) {
            this.error = "Error";
            this.iframeDisabled = true;
            return;
        }
        if (this.to.diff(this.from, "hours") > 3) {
            this.error = "Select a range of less than 3 hours";
            this.iframeDisabled = true;
            return;
        }

        this.error = undefined;
        this.loading = true;
        this.dateRangeChanged = false;
        this.iframeDisabled = true;

        try {
            this.model = await this.nd.causalityChart(this.source.id, this.from, this.to);
        } catch (e) {
            this.loading = false;
            return;
        }

        setTimeout(() => {
            this.loading = false;
            this.iframeDisabled = false;
        }, 0);
    }

    reset() {
        this.error = undefined;
        this.iframeDisabled = true;
    }
}
